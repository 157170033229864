<script>
import Layout from "./base";
import Footer from "../../components/footer2";

/**
 * Starter component
 */
export default {
    components: { Layout, Footer },
    props:{
        regiones:{},
        comunas:{},
        direccion:'',
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                    text: "Utility",
                    href: "/"
                },
                {
                    text: "Starter Page",
                    active: true
                }
            ],
            desc:0,
            envio:null,
            datos_envio:{
                nombre:'',
                apellidos:'',
                rut:'',
                direccion:'',
                direccion2:'',
                region:'',
                comuna:'',
                telefono:'',
                email:'',
                otra_nombre:'',
                otra_apellidos:'',
                otra_rut:'',
                otra_direccion:'',
                otra_direccion2:'',
                otra_dir:'',
                otra_region:'',
                otra_comuna:'',
                notas:''
            },
            bool_entregar:false,
            bool_recoger:false,
            selected:'',//modificable la agregue para que no tirara el error
            boton_webpay:false,
        };
    },
    computed:{
        comunasFiltradas() {
            if (!this.datos_envio.region) return [];
                return this.comunas.filter(comuna => comuna.region_id === this.datos_envio.region);
        },
        comunasFiltradas_otras() {
            if (!this.datos_envio.otra_region) return [];
                return this.comunas.filter(comuna => comuna.region_id === this.datos_envio.otra_region);
        },
        cartItems(){
            return this.$store.getters.cartItems;
        },
        cartTotal() {
            return this.$store.getters.cartTotal;
        },
        total(){
            let total=0;
            total= this.cartTotal + this.envio - this.desc
            return total
        },
    },
    methods:{
        entregar(){
            this.bool_entregar=true;
            this.bool_recoger=false;
            this.envio=this.regiones.filter(region => region.id === this.datos_envio.region)[0].valor;
        },
        recoger(){
            this.bool_entregar=false;
            this.bool_recoger=true;
            this.envio=0;
        },
        pagar_webpay(){
            if (this.validarCampos()) {
                this.boton_webpay=true
                let payload={
                    cartItems:this.$store.getters.cartItems,
                    cartTotal:this.$store.getters.cartTotal,
                    envio:this.envio,
                    desc:this.desc,
                    tipo:'Webpay',
                    rut:this.datos_envio.rut,
                    telefono:this.datos_envio.telefono,
                    email:this.datos_envio.email,
                    datos_envio:this.datos_envio,
                }
                console.log(payload)
                axios.post('/api/nueva_orden',payload).then(res=>{
                    if (res.data.estado) {
                        window.location.href = `/orden/${res.data.orden.uid}`;
                    }else{
                        console.log(res.data.mensaje);
                        this.boton_webpay=false
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        const errors = error.response.data.errors;
                        Object.keys(errors).forEach(key => {
                            errors[key].forEach(message => {
                                // Aquí puedes mostrar cada mensaje de error en tu interfaz de usuario
                                console.error(message);
                            });
                        });
                        this.boton_webpay=false
                    } else {
                        console.error(error);
                    }
                })
            } else {
                /*let camposFaltantes = this.obtenerCamposFaltantes();
                let mensaje = 'Por favor, completa los siguientes campos obligatorios:\n';
                camposFaltantes.forEach(campo => {
                    mensaje += '- ' + campo + '\n';
                });
                confirm(mensaje);*/
                console.log('Formulario inválido');
            }
        },
        obtenerCamposFaltantes() {
            let camposFaltantes = [];
            let objeto = Object.keys(this.datos_envio)
            Object.keys(this.datos_envio).forEach(campo => {
                if (this.datos_envio[campo].toString().trim() === '' && campo !== 'otra_dir' && campo !== 'direccion2' && campo !== 'otra_nombre' && campo !== 'otra_apellidos' && campo !== 'otra_rut' && campo !== 'otra_direccion' && campo !== 'otra_direccion2' && campo !== 'otra_dir' && campo !== 'otra_region' && campo !== 'otra_comuna') {
                    camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                }
            });
            if(this.datos_envio.otra_dir){
                objeto.forEach(campo => {
                    if (this.datos_envio[campo].toString().trim() === '' && campo !== 'otra_dir' && campo !== 'otra_direccion2' && campo !== 'nombre' && campo !== 'apellidos' && campo !== 'rut' && campo !== 'direccion' && campo !== 'direccion2' && campo !== 'region' && campo !== 'comuna') {
                        camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                    }
                });
            }
            return camposFaltantes;
        },
        validarCampos() {
            return (
                this.datos_envio.nombre.trim() !== '' &&
                this.datos_envio.apellidos.trim() !== '' &&
                this.datos_envio.rut.trim() !== '' &&
                this.datos_envio.direccion.trim() !== '' &&
                this.datos_envio.region !== '' &&
                this.datos_envio.comuna !== '' &&
                this.datos_envio.telefono.trim() !== '' &&
                this.datos_envio.email.trim() !== ''
                // Agrega más validaciones aquí para otros campos si es necesario
            );
        },
        otra_region(){
            if(!this.datos_envio.otra_dir){
                this.datos_envio.otra_region='';
            }
            if(this.datos_envio.otra_region!=''){
                this.envio=this.regiones.filter(region => region.id === this.datos_envio.otra_region)[0].valor;
            }else{
                this.envio=this.regiones.filter(region => region.id === this.datos_envio.region)[0].valor;
            }
        }
    },
    created() {
        //this.selected='Estándar';
        this.regiones = JSON.parse(this.regiones);
        this.comunas = JSON.parse(this.comunas);
        if(this.direccion!==''){
            this.direccion=JSON.parse(this.direccion)
            this.datos_envio.direccion=this.direccion.direccion
            this.datos_envio.direccion2=this.direccion.direccion2
            this.datos_envio.region=this.direccion.region
            this.datos_envio.comuna=this.direccion.comuna
        }
    }
};
</script>
<template>
    <div>
    <!-- Begin page -->
        <div id="layout-wrapper">
            <header id="page-topbar">
                <div class="navbar-header bg-primary">
                    <div class="d-flex">
                        <div class="navbar-brand-box">
                            <a href="/" class="logo logo-dark">
                                <span class="logo-sm">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                                <span class="logo-lg">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                            </a>
                            <a href="/" class="logo logo-light">
                                <span class="logo-sm">
                                <img src="/archivos_base/logoblanco.png" alt height="22" />
                                </span>
                                <span class="logo-lg">
                                <img src="/archivos_base/logoblanco.png" alt height="40" />
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="bg-light p-2 text-center">
                    <h4 >Envío gratis desde $200.000</h4>
                </div>
            </header>
            
            <!--<HorizontalNavInicio />-->
            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content2">
                <div class="page-content2">
                    <div class="container-fluid mt-5">
                        <div class="row">
                            <div class="col-xl-8">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="accordion" role="tablist">
                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block v-b-toggle.accordion-1 variant="primary">1 - Datos Personales</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Email:" label-for="text">
                                                            <b-form-input for="text" value="" v-model="datos_envio.email"></b-form-input>
                                                        </b-form-group>
                                                        <b-form-group v-slot="{ ariaDescribedby }">
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <b-form-radio v-model="datos_envio.comprobante" :aria-describedby="ariaDescribedby" name="some-radios" value="Boleta">Boleta</b-form-radio>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-radio v-model="datos_envio.comprobante" :aria-describedby="ariaDescribedby" name="some-radios" value="Factura">Factura</b-form-radio>
                                                                </div>
                                                            </div>
                                                        </b-form-group>
                                                        <div v-if="datos_envio.comprobante=='Boleta'">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.nombre" required></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Apellidos" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.apellidos" required></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut " label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.rut" placeholder="11.111.111-1"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región" label-for="text">
                                                                <select id="region" class="custom-select" v-model="datos_envio.region">
                                                                    <option value="" disabled selected>Selecciona una región</option>
                                                                    <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Comuna" label-for="text">
                                                                <select id="comuna" class="custom-select" v-model="datos_envio.comuna" v-if="datos_envio.region">
                                                                    <option value="" disabled selected>Selecciona una comuna</option>
                                                                    <option v-for="comuna in comunasFiltradas" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                                                                <b-form-input for="text" value="+569 " v-model="datos_envio.telefono" placeholder="+569 1111 111"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                        <div v-else-if="datos_envio.comprobante=='Factura'">
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Razón Social:" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.nombre" required></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de Facturación" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.rut" placeholder="11.111.111-1"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                                                <b-form-input for="text" value="" v-model="datos_envio.direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región" label-for="text">
                                                                <select id="region" class="custom-select" v-model="datos_envio.region">
                                                                    <option value="" disabled selected>Selecciona una región</option>
                                                                    <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Comuna" label-for="text">
                                                                <select id="comuna" class="custom-select" v-model="datos_envio.comuna" v-if="datos_envio.region">
                                                                    <option value="" disabled selected>Selecciona una comuna</option>
                                                                    <option v-for="comuna in comunasFiltradas" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                                </select>
                                                            </b-form-group>
                                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                                                                <b-form-input for="text" value="+569 " v-model="datos_envio.telefono" placeholder="+569 1111 111"></b-form-input>
                                                            </b-form-group>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block v-b-toggle.accordion-2 variant="primary">2 - Entrega / Retiro</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <div class="row text-center">
                                                            <div class="offset-md-2 col-md-4">
                                                                <b-button variant="primary" pill block @click="entregar()">Entregar</b-button>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <b-button variant="primary" pill block @click="recoger()">Recoger</b-button>
                                                            </div>
                                                            
                                                        </div>
                                                        <div v-show="bool_entregar">                                                            
                                                            <div v-show="datos_envio.comuna!=''">
                                                                <p>Método de entrega</p>
                                                                <div class="row">
                                                                    <div class="col-md-11">
                                                                        <b-form-group >
                                                                            <!-- <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios">Estándar</b-form-radio> -->
                                                                            <p>Estándar</p>
                                                                            <p>Fecha posible de entrega siesque se pudiera {{ selected }}</p>
                                                                        </b-form-group>
                                                                    </div>
                                                                    <div class="col-md-1">
                                                                        <p>{{this.envio}}</p>
                                                                    </div>
                                                                </div>
                                                                <h4>Entrega</h4>
                                                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="¿Enviar a una dirección diferente?" label-for="text">
                                                                    <input id="checkbox" type="checkbox" name="¿Enviar a una dirección diferente?" v-model="datos_envio.otra_dir" @change="otra_region()" value="0">
                                                                </b-form-group>
                                                                <div v-if="datos_envio.otra_dir">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre de quien recibe:" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_nombre"></b-form-input>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región" label-for="text">
                                                                        <select id="region" class="custom-select" v-model="datos_envio.otra_region" @change="otra_region()">
                                                                            <option value="" disabled selected>Selecciona una región</option>
                                                                            <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                                        </select>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Comuna" label-for="text">
                                                                        <select id="comuna" class="custom-select" v-model="datos_envio.otra_comuna" :disabled="datos_envio.otra_region==''">
                                                                            <option value="" disabled selected>Selecciona una comuna</option>
                                                                            <option v-for="comuna in comunasFiltradas_otras" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                                        </select>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de quien recibe" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_rut" placeholder="11.111.111-1"></b-form-input>
                                                                    </b-form-group>
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                                                        <b-form-input for="text" value="" v-model="datos_envio.otra_direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="text-center mt-4" v-show="bool_recoger">
                                                            <h4 class="mb-4">Punto de Retiro: </h4>
                                                            <img src="/archivos_base/logo.png" alt height="40" />
                                                            <p>Errázuriz 2066, Valdivia</p>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <b-card no-body class="mb-1">
                                                <b-card-header header-tag="header" class="p-1" role="tab">
                                                    <b-button block v-b-toggle.accordion-3 variant="primary">3 - Pago</b-button>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                                                    <b-card-body>
                                                        <b-tabs content-class="mt-3" align="center">
                                                            <b-tab title="First" active>
                                                                <template #title>
                                                                    <div class="text-center">
                                                                        <img src="/archivos_base/webpay.png" alt  height="30"/>
                                                                        <p class="m-0 p-0 font-size-12 text-secondary">Tarjeta Crédito/Débito</p>
                                                                    </div>
                                                                </template>
                                                                <div class="border-2 text-center">
                                                                    <p class="p-2 m-0 font-size-16"><b>Haz click en "Finalizar compra" para ingresar los datos de tu Tarjeta de Débito/Crédito</b></p>
                                                                </div>
                                                            </b-tab>
                                                            <!-- <b-tab title="Second">
                                                                <p>I'm the second tab</p>
                                                            </b-tab>
                                                            <b-tab title="Disabled">
                                                                <p>I'm a disabled tab!</p>
                                                            </b-tab> -->
                                                        </b-tabs>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="card">
                                    <div class="card-body">
                                        <h4 class="card-title mb-3">Resumen de Compra</h4>
                                        <div class="dropdown-divider"></div>
                                        <div class="row">
                                            <div class="col-md-12" v-for="(item,index) in cartItems" :key="index">
                                                <div class="row">
                                                    <div class="col-md-1">
                                                        <img :src="item.imagen" alt="product-img" title="product-img" class="avatar-xs" />
                                                    </div>
                                                    <div class="col-md-11">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <p>{{item.nombre}}</p>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <input type="text" class="form-control text-center" disabled :value="item.cantidad">
                                                            </div>
                                                            <div class="col-md-9 text-right">
                                                                <p>$ {{item.precio*item.cantidad}}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="dropdown-divider"></div>
                                            </div>
                                        </div>
                                        
                                        <div class="table-responsive">
                                            <table class="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Total :</td>
                                                        <td>$ {{ cartTotal }}</td>
                                                    </tr>
                                                    <tr v-if="desc!=0">
                                                        <td>Descuento :</td>
                                                        <td>- $ 157</td>
                                                    </tr>
                                                    <tr >
                                                        <td>Envio :</td>
                                                        <td v-if="envio==null">Sin datos de envio</td>
                                                        <td v-else>${{envio}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Total :</th>
                                                        <th>$ {{total}}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-center">
                                                <b-button class="mb-3" variant="primary" pill block @click="pagar_webpay()">Finalizar Compra</b-button>
                                                <a href="/resumen_compra" >
                                                    Volver al Carrito
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- container-fluid -->
                </div>
                <!-- End Page-content -->
                <Footer/>
            </div>
        <!-- end main content-->
        </div>
    </div>
</template>