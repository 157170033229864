<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import {jsPDF} from 'jspdf'; 


export default {
    components: { Layout, PageHeader ,jsPDF},
    data() {
        return {
            title: "CLIENTES",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "fantasia",label:'Nombre fantasía', sortable: true },
                { key: "nombre",label:'Razón social', sortable: true },
                { key: "rut", label:'Rut',sortable: true },
                { key: "cantidad", label:'N° licitaciones',sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            clientes:[],
            Componentes_Cliente:{
                id:'',
                nombre:null,
                rut:null,
                fantasia:null
            },
            id:'',
            licitaciones:[],
            productos:[],
            a:[],
            formato_imagen:'',
            validador_RUT:'',
            Mostrar_cliente:false,
            Mostrar_boton_crear:true,
            showbutton_modificarcliente:false
        };
    },
    computed: {
        rows() {
            return this.clientes.length;
        }
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        camposmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            }).then(()=>{
                window.location.href = `/clientes`;
            });
        },
        validacionmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            });
        },
        eliminarmsg(text) {
            Swal.fire({
                icon: 'success',
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver"
            }).then(()=>{
                window.location.href = `/clientes`;
            });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        CrearCliente(){
            this.$refs['Crear_Cliente'].show();
        },
        Crear(){
            this.Mostrar_boton_crear=true
            if(this.Componentes_Cliente.nombre == null || this.Componentes_Cliente.rut == null){
                this.validacionmsg('No puede haber campos vacios','warning');
            }else{
                //console.log(this.Componentes_Cliente);
                axios.post('/api/crear_cliente',this.Componentes_Cliente).then(res=>{
                    if(res.data.estado){
                        this.camposmsg(res.data.mensaje,res.data.icon);
                        this.Componentes_Cliente=
                            {
                            rut:null,
                            nombre:null,
                            fantasia:null
                            };
                    }
                })
            }
        },
        Volver(){
            this.Componentes_Cliente={
                rut:null,
                nombre:null,
                fantasia:null
            };
            this.$refs['Crear_Cliente'].hide()
            this.$refs['Ver_Cliente'].hide();
            this.Mostrar_cliente=false
        },
        Volver2(){
            this.$refs['Ver_Productos'].hide();
        },
        Volvereditar(){
            this.Componentes_Cliente={
                rut:null,
                nombre:null,
                fantasia:null
            };

            this.$refs['Modificar_cliente'].hide()
        },
        showModaleditar(data){
            this.$refs['Modificar_cliente'].show();
            this.Componentes_Cliente=
                {
                id:data.id,
                nombre:data.nombre,
                rut:data.rut,
                fantasia:data.fantasia,
                
                };
            //console.log(data);
            //console.log(this.Componentes_cliente);
        },
        showModalver(data){
            this.id=data.id
            //console.log(this.id);

            axios.get('/api/ver_cliente/'+this.id).then(res=>{
                    if(res.data.estado){
                        this.licitaciones = res.data.licitaciones
                        //console.log('data cliente:'+this.licitaciones);
                        this.$refs['Ver_Cliente'].show();
                    }
            })
            
        },
        Modificar(){
            this.showbutton_modificarcliente=true
            if(this.Componentes_Cliente.nombre == '' || this.Componentes_Cliente.rut == ''){
                this.showbutton_modificarcliente=false
                return this.validacionmsg('No puede haber campos vacios','warning');
            }else{
                axios.post('/api/modificar_cliente',this.Componentes_Cliente).then(res=>{
                    if(res.data.estado){
                        this.camposmsg(res.data.mensaje,res.data.icon);
                        this.Componentes_Cliente=
                            {
                            rut:null,
                            nombre:null,
                            fantasia:null,
                            };
                    }
                })
            }
        },
        Eliminar(data){
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Componentes_Cliente={id:data.id,};
                    axios.post('/api/eliminar_cliente',this.Componentes_Cliente).then(res=>{
                        if(res.data.estado){
                            this.eliminarmsg(res.data.mensaje);
                            this.Componentes_Cliente=
                                {
                                rut:null,
                                nombre:null,
                                };
                        }
                    })
                }
            }) 
        },
        Ver_Productos(data){
            //console.log(data);
            axios.get('/api/ver_producto/'+data).then(res=>{
                    if(res.data.estado){
                        this.productos = res.data.productos
                        //console.log(this.productos);
                        this.$refs['Ver_Productos'].show();
                    }
            })
        },
        validarrut(){  
            //console.log(this.Componentes_Cliente.rut); 
            let data={
                rut:this.Componentes_Cliente.rut,
                url:'https://api.libreapi.cl/rut/activities?rut='
            }
            axios.post('/api/get_url',data).then(res=>{
                //console.log(res.data.response);
                if(res.data.response.status!='fail'){
                    //console.log(res.data.response.data.name);
                    if(res.data.response.data.name===""){
                        //console.log('rut incorrecto');
                        this.$toasted.error('rut incorrecto');
                        this.Mostrar_cliente=false
                        this.Mostrar_boton_crear=true
                        this.Componentes_Cliente.nombre=null
                    }else{
                        //console.log('rut correcto');
                        //console.log(res.data.response);
                        this.Componentes_Cliente.nombre=res.data.response.data.name
                        this.$toasted.success('rut correcto');
                        this.Mostrar_cliente=true
                        this.Mostrar_boton_crear=false
                        //this.Crear();
                    }
                }
                else{
                    this.$toasted.error('rut incorrecto');
                    this.Mostrar_cliente=false
                    this.Mostrar_boton_crear=true
                    //console.log(res.data.response.status);
                }
                }).catch(error => console.log(error))      
        },
        Exportarpdf(data){
            axios.get('/api/ver_producto/'+data.id).then(res=>{
                if(res.data.estado){
                    this.productos = res.data.productos
                    var doc = new jsPDF({ putOnlyUsedFonts: false, orientation: "portrait" ,format:'legal'});
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "bold");
                    doc.addImage("/archivos_base/logo03.png", "PNG", 85, 3, 40, 30,'FAST');
                    doc.text("ID : "+data.num, 20, 35);
                    doc.text("Nombre : "+data.nombre, 20, 40);
                    doc.text("Cliente : "+data.cliente, 20, 45);
                    if(data.condicion!=null){
                        doc.text("Condición : "+data.condicion, 20, 50);
                    }        
                    doc.text("Productos : ", 20, 55);
                    var a = 60
                    doc.setFont("helvetica", "normal");
                    //console.log('despues'+this.productos);
                    this.productos.forEach((element,index) => {
                        var b = 0
                        if(index==0){
                            b=a
                        }else{
                            b=10   
                        }
                        let final_caracteristicas=0
                        let altura=0
                        for (const property in element){
                            if(element[property] != null && element[property] != 'null'){
                                if(property =='codigo'){
                                    b=b+5
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Código:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='nombre'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Nombre:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                /*
                                if(property =='descripcion'){
                                    doc.text("Descripción: "+element[property], 20, b)
                                }
                                */
                                if(property =='marca'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Marca:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='fabricacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Fabricación:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='vencimiento'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Vencimiento:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                /*
                                if(property =='duracion'){
                                    doc.text("Duración: "+element[property], 20, b)
                                }
                                */
                                if(property =='certificacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Certificación: ", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                if(property =='presentacion'){
                                    b=b+10
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Presentación:", 20, b)
                                    doc.setFont("helvetica", "normal");
                                    doc.text(element[property], 45, b)
                                }
                                /*
                                if(property =='envio'){
                                    doc.text("Envío: "+element[property], 20, b)
                                }
                                if(property =='plazoentrega'){
                                    doc.text("Plazo de entrega: "+element[property], 20, b)
                                }
                                */
                                if(property =='caracteristicas'){
                                    b=b+10
                                    
                                    let texto_lineas = element[property]
                                    let valor_amostrar = element[property]
                                    //contar saltos de linea
                                    let regEx = new RegExp('\\n','g')
                                    let valor_propiedad = element[property].replace(regEx,'@')
                                    let a = 0
                                    for(var i=0; i<valor_propiedad.length; i++) {
                                    if(valor_propiedad.charAt(i) === '@'){
                                        a = a+ 1
                                    }
                                    }
                                    // contar lineas
                                    let z = 0
                                    let regEx2 = new RegExp('\\s+','g')
                                    let valor_propiedad_2 = texto_lineas.replace(regEx2,' ')
                                    final_caracteristicas = valor_propiedad_2.length
                                    z = Math.trunc(parseInt(final_caracteristicas)/104)
                                    let y=a+z

                                    if(y == 14 || y == 13 | y == 12){
                                        altura = 60
                                    }else if(y == 11 || y == 10 || y == 9){
                                        altura = 50
                                    }
                                    else if(y == 8 || y == 7 || y == 6){
                                        altura = 40
                                    }
                                    else if(y == 5 || y == 4 || y == 3){
                                        altura = 40
                                    }else if(y == 2 || y == 1 || y == 0){
                                        altura = 20
                                    }
                                    
                                    doc.setFont("helvetica", "bold");
                                    doc.text("Caracteristicas:", 20, b,{ align: "justify", lineHeightFactor: 1.5, maxWidth: 170 })
                                    doc.setFont("helvetica", "normal");                                  
                                    doc.text(valor_amostrar, 20, b+5,{ lineHeightFactor: 1.5, maxWidth: 170 })
                                    
                                }
                                
                            }
                        }

                        if(element.foto_dir_resize.includes('png')){
                            this.formato_imagen='PNG'  
                        }
                        if(element.foto_dir_resize.includes('jpg')){
                            this.formato_imagen='JPG'
                        }
                        if(element.foto_dir_resize.includes('jpeg')){
                            this.formato_imagen='JPEG'
                        }
                        doc.addImage(element.foto_dir_resize, this.formato_imagen,50, b+altura,0, 80); //b-50  104
                        doc.addImage("/archivos_base/vampro_tira.png", this.formato_imagen,0, 320,217, 12); //320 pie de pagina
                        doc.addPage();    
                    });
                    doc.setFontSize(10);
                    doc.addImage("/archivos_base/logo.png", "PNG", 85, a+55, 40, 20,'FAST');
                    doc.text("Dirección: Errázuriz 2066 – Valdivia", 105, a+80, null, null, "center");
                    doc.text("Teléfono: +56 63 2 204385", 105, a+90, null, null, "center");
                    doc.text("Email: contacto@vamproden.cl", 105, a+100, null, null, "center");
                    doc.text("www.vamproden.cl", 105, a+110, null, null, "center");
                
                    doc.save(data.num+'_'+data.nombre);
                    //this.camposmsg('Exportación correcta','warning')
                    //console.log(this.productos);
                }
            });
            
        },
    },
    created(){
        axios.get('/api/get_clientes').then(res=>{
            this.clientes = res.data.clientes;
            //console.log(this.clientes);
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="row pr-1">
                            <div class="col-10">
                                <h4 class="card-title">Clientes</h4>
                            </div>
                            <div class="col-2 ">
                                <b-button class="float-right mr-0" variant="info" @click="CrearCliente()">Agregar Cliente</b-button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table class="table table-striped" hover
                                :items="clientes"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(fantasia)="datafantasia">
                                    <p v-if="datafantasia.item.fantasia=='null' || datafantasia.item.fantasia==null || datafantasia.item.fantasia==''" style="color:DarkGray" ><i class="bx bx-error"></i> Sin nombre</p>
                                    <p v-if="datafantasia.item.fantasia!='null'">{{datafantasia.item.fantasia}}</p>
                                </template>

                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" variant="info" @click="showModalver(data.item)" >Ver</b-button>
                                    <b-button size="sm" variant="info" @click="showModaleditar(data.item)" >Editar</b-button>
                                    <b-button size="sm" variant="info" @click="Eliminar(data.item)">Eliminar</b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" pagination-active-color="white"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal ref="Ver_Cliente" title="Licitaciones del cliente" hide-footer size="xl">
                            <div v-if="licitaciones==''">
                                <h2>Este cliente de momento no tiene licitaciones</h2>
                            </div>
                            <div v-if="licitaciones!=''">
                                <div class="row">
                                    <div class="col-12">
                                        <form class="form-horizontal" role="form">
                                            <div class="card-body">
                                                <h4 class="card-title">Detalle:</h4>
                                                <div class="table-responsive">
                                                <table class="table mb-0 table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Nombre</th>
                                                            <th>Cliente</th>
                                                            <th>Acción</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="item in licitaciones" :key="item.id" >
                                                        <td>{{item.num}}</td>
                                                        <td>{{item.nombre}}</td>
                                                        <td>{{item.cliente}}</td>
                                                        <td><b-button variant="info" @click="Ver_Productos(item.licitacion_id)">Productos</b-button></td>
                                                        <td><b-button variant="info" @click="Exportarpdf(item)">Exportar</b-button></td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>      
                            <div class="text-right">
                                <b-button variant="info" @click="Volver()">Volver</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="Ver_Productos" title="Productos" hide-footer size="xl">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <div class="card-body">
                                            <h4 class="card-title">Detalle:</h4>
                                            <div class="table-responsive">
                                            <table class="table mb-0 table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Código</th>
                                                        <th>Nombre</th>
                                                        <th>Marca</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in productos" :key="item.id" >
                                                    <td>{{item.codigo}}</td>
                                                    <td>{{item.nombre}}</td>
                                                    <td>{{item.marca}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volver2()">Volver</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="Crear_Cliente" title="Crear Cliente" hide-footer size="xl">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <div class="row">

                                        </div>
                                        <b-form-group id="rut_id" label-cols-sm="2" label-cols-lg="2" label="Rut" label-for="text">
                                            
                                            <div class="row">
                                                <div class="col-md-10 col-8">
                                                    <b-form-input for="text" v-model="Componentes_Cliente.rut" placeholder="Ingrese rut sin puntos y con guión"></b-form-input>
                                                </div>
                                                <div class="col-md-2 col-4">
                                                    <b-button variant="info" block @click="validarrut()">Validar</b-button>
                                                </div>
                                            </div>
                                                         
                                        </b-form-group>
                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Razón social" label-for="text" v-if="Mostrar_cliente">
                                            <b-form-input for="text" v-model="Componentes_Cliente.nombre" placeholder="Ingrese razón social" readonly></b-form-input>
                                        </b-form-group>

                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre fantasía" label-for="text" v-if="Mostrar_cliente">
                                            <b-form-input for="text" v-model="Componentes_Cliente.fantasia" placeholder="Ingrese nombre de fantasía"></b-form-input>
                                        </b-form-group>

                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volver()">Volver</b-button>
                                <b-button :disabled="Mostrar_boton_crear" variant="success" @click="Crear()">Crear</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="Modificar_cliente" title="Modificar Cliente" hide-footer size="xl">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">

                                        <b-form-group id="rut_id" label-cols-sm="2" label-cols-lg="2" label="Rut" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.rut" readonly></b-form-input>
                                        </b-form-group>

                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Razón social" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.nombre" readonly></b-form-input>
                                        </b-form-group>

                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre fantasía" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Cliente.fantasia"></b-form-input>
                                        </b-form-group>

                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volvereditar()">Volver</b-button>
                                <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarcliente">Modificar</b-button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style>
.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #50a5f1;  
}

.modal-header .close {
  display:none;
}


</style>