<script>
import Layout from "./base";
import Productos from "../componentes/productos";

/**
 * Starter component
 */
export default {
    components: { Layout, Productos },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:'',
        productos:{},
        tipo:'',
        id:'',
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            productos1:[],
            productos2:[],
            categorias:[],
            marcas:[],
            category:[],
            marcary:[],
            orden:'',
            filter:'',
        };
    },
    watch:{
        filter(newValue2){
            this.productos2= this.productos1.filter(producto => producto.nombre.toLowerCase().includes(newValue2.toLowerCase()));
        },
    },
    methods:{
        ver_detalle(producto){
            if (producto.ver == true) {
                producto.ver = false;
            }
            else if (producto.ver == false) {
                producto.ver = true;
            }
        },
        ordenar(){
            if (this.orden==1) {
                this.productos2 = this.productos2.sort((a, b) => new Date(a.created_at) > new Date(b.created_at));
            }else if(this.orden==2){
                this.productos2=this.productos2.sort((x, y) => x.nombre.localeCompare(y.nombre));
            }else if(this.orden==3){
                this.productos2=this.productos2.sort((x, y) => x.precio_activo - y.precio_activo);
            }else if(this.orden==4){
                this.productos2=this.productos2.sort((x, y) => y.precio_activo - x.precio_activo);
            }else if(this.orden==5){
                this.productos2=this.productos2.filter(producto => producto.tipo=='Simple' ? producto.precio_rebajado!=null : producto.precio_rebajado!=0);
            }else if(this.orden==''){
                axios.get('/api/get_productos_publicados').then(res=>{
                    this.productos1 = res.data.productos;
                    this.productos2 = res.data.productos;
                });
            }
        },
        filtro(){
            if(this.category.length!=0 && this.marcary.length==0){
                let formData = new FormData();
                formData.append('tipo', 'categoria');
                formData.append('ids', this.category);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos1 = res.data.productos;
                    this.productos2 = res.data.productos;
                });
            }else if(this.category.length==0 && this.marcary.length!=0){
                let formData = new FormData();
                formData.append('tipo', 'marca');
                formData.append('ids', this.marcary);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos1 = res.data.productos;
                    this.productos2 = res.data.productos;
                });
            }else if(this.category.length!=0 && this.marcary.length!=0){
                let formData = new FormData();
                formData.append('tipo', 'categoriamarca');
                formData.append('ids', '');
                formData.append('categorias', this.category);
                formData.append('marcas', this.marcary);
                axios.post('/api/get_productos_web_filtro',formData).then(res=>{
                    this.productos1 = res.data.productos;
                    this.productos2 = res.data.productos;
                });
            }
            else if(this.category.length==0 && this.marcary.length==0){
                axios.get('/api/get_productos_publicados').then(res=>{
                    this.productos1 = res.data.productos;
                    this.productos2 = res.data.productos;
                });
            }
        },
    },
    created(){
        axios.get('/api/get_categorias_productos').then(res=>{
            this.categorias=res.data.categorias;
        });
        axios.get('/api/get_marcas_productospubl').then(res=>{
            this.marcas=res.data.marcas;
        });
        if (this.tipo=='marca') {
            this.marcary.push(this.id);
        }else if (this.tipo=='categoria') {
            this.category.push(this.id);
        }
        this.filtro();
    }
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div class="row">
            <div class="col-md-3 bg-light">
                <div class="card bg-light">
                    <div class="card-body text-black">
                        <h5 class="mb-4">Categorías del Producto</h5>
                        <div class="row" v-for="(categoria,index1) in categorias" :key="'cat'+index1">
                            <div class="col-9 pt-1">
                                <b-form-checkbox
                                    :value="categoria.id"
                                    v-model="category"
                                    @change="filtro"
                                    >
                                    {{ categoria.nombre }}
                                </b-form-checkbox>
                            </div>
                            <div class="col-3 pt-1">
                                <div class="circulo">
                                    <p class="m-3">{{ categoria.total_product }}</p>
                                </div>
                            </div>
                        </div>
                        <h5 class="mt-4 mb-4">Marcas de Productos</h5>
                        <div class="row" v-for="(marca,index2) in marcas" :key="index2">
                            <div class="col-9 pt-1">
                                <b-form-checkbox
                                    :value="marca.id"
                                    v-model="marcary"
                                    @change="filtro()"
                                    >
                                    {{ marca.nombre }}
                                </b-form-checkbox>
                            </div>
                            <div class="col-3 pt-1">
                                <div class="circulo">
                                    <p class="m-3">{{ marca.total_product }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-9 bg-white">
                <div class="card">
                    <div class="mt-4">
                        <h5 class="mb-4">Busca tus productos</h5>
                        <div class="row">
                            <form class="col-md-3 app-search text-black">
                                <div class="ml-2 position-relative text-black">
                                    <input type="text" class="form-control" placeholder="Buscar ..." v-model="filter" />
                                    <span class="bx bx-search-alt text-primary"></span>
                                </div>
                            </form>
                        </div>
                        <div class="row">
                            <!-- <b-button class="mt-3 ml-2" variant="light" size="sm"> <span class="bx bx-search-alt"></span></b-button>
                            <b-button class="mt-3 ml-2" variant="light" size="sm"> <span class="bx bx-search-alt"></span></b-button>-->
                            <div class="col-md-3">
                                <select class="mt-3 ml-2 custom-select custom-select-sm" v-model="orden" @change="ordenar">
                                    <option value="">Orden por defecto</option>
                                    <option value="1">Agregados recientemente</option>
                                    <option value="2">Nombre: A a la Z</option>
                                    <option value="3">Precio: Menor a mayor</option>
                                    <option value="4">Precio: Mayor a menor</option>
                                    <option value="5">Con descuento</option>
                                </select>
                            </div>
                            <div class="offset-md-6 col-md-3 text-right">
                                <p class="text-secondary"><b>Resultados: {{ productos2.length }} productos</b></p>
                            </div>
                        </div>
                        <Productos :titulo="''" :productos="productos2"/>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.espacio{
    margin-bottom: 52px;
}
</style>