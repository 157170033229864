<script>

/**
 * Starter component
 */
export default {
    props:{
        titulo:'',
        productos:[],
    },
    data() {
        return {
            
        };
    },
    methods:{
    }
};
</script>
<template>
    <div>
        <h3 class="pl-3 pt-4">{{ titulo }}</h3>
        <div class="product-container">
            <div class="product-card" v-for="(producto,index) in productos" :key="index">
                <div class="border pt-3 pb-3 text-center" >
                    <img :src="'/images/productos_web/producto-sin-imagen.png'" alt class="rounded avatar-3xl" v-if="producto.foto_dir=='' || producto.foto_dir==null"/>
                    <img :src="producto.foto_dir" alt class="rounded avatar-3xl" v-else/>
                </div>
                <p class="p-1 m-0 text-danger font-size-12" v-if="producto.precio_rebajado!=null && producto.precio_rebajado!=0">Oferta - {{producto.porcentaje}}% OFF</p>
                <p class="p-1 m-0 text-danger font-size-12" v-else><br></p>
                <!-- <p class="p-1 m-0 text-secondary font-size-10" v-for="cate in producto.cat" :key="cate.id">{{cate.nombre}}</p> -->
                <h2 class="product-title">{{producto.nombre}}</h2>
                <span v-if="producto.tipo=='Simple'">
                    <p class="product-price" v-if="producto.precio_rebajado!=null"><del class="text-secondary">${{ producto.precio_normal }}</del> - ${{producto.precio_rebajado}}</p>
                    <p class="product-price" v-else>$ {{producto.precio_normal}}</p>
                </span>
                <span v-else-if="producto.tipo=='Variable'">
                    <p class="product-price" v-if="producto.precio_rebajado!=0">Desde $ {{producto.precio_rebajado}}</p>
                    <p class="product-price" v-else>Desde $ {{producto.precio_normal}}</p>
                </span>
                <div class="m-0 p-0">
                    <p class="float-right text-primary font-size-12 m-0 p-0"><b>{{producto.nombre_marca}}</b></p>
                </div>
                <div class="mt-auto pt-4 p-0 m-0 card-footer" style="background-color: transparent;">
                    <b-button variant="primary" block pill :href="'/producto/'+producto.id">Ver producto</b-button>
                </div>
            </div>
        </div>                    
    </div>
</template>