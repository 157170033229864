<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import Axios from 'axios';
import LoadingMessage from '../LoadingMessage.vue';

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, DatePicker,LoadingMessage},
    data() {
        return {
            isLoading:false,
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            fecha:{},
            libros:[],
            libros_malos:[],
            cantidad_libros_malos:0,
            registro:'',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                //{ key: "id", sortable: true },
                { key: "folio", sortable: true },
                { key: "fecha", sortable: true },
                { key: "nombre", sortable: true },
                { key: "glosa", sortable: true },
                { key: "tipo", sortable: true },
                { key: "cuenta", sortable: true },
                { key: "doc", sortable: true },
                { key: "cr", sortable: true },
                { key: "numero", sortable: true },
                { key: "debe", sortable: true },
                { key: "haber", sortable: true },
                { key: "total_debe", sortable: true },
                { key: "total_haber", sortable: true },
                { key: "accion", sortable: true },
            ]
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
        return this.libros.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        showModaleditar(data){
            this.registro=data
            this.registro.glosa_antigua=data.glosa
            this.$refs['Modificar_Libro'].show();
        },
        Modificar(){
            this.isLoading = true;
            axios.post('/api/modificar_libro',this.registro).then(res=>{
                if(res.data.estado){
                    this.libros=res.data.libros_corregidos
                    this.libros_malos = res.data.libros_malos
                    this.cantidad_libros_malos = res.data.cantidad_libros_malos
                    this.CerrarEditar()
                    this.isLoading = false;
                }
            })
        },
        CerrarEditar(){
            this.$refs['Modificar_Libro'].hide();
        },
        buscar(){
            this.isLoading = true;
            let payload={
                ini:this.fecha[0],
                fin:this.fecha[1],
                tipo:this.tipos_libros
            }
            axios.post('/api/get_rango_fechas',payload).then(res=>{
                if(res.data.estado){
                    this.libros=res.data.reg
                    console.log(res.data.reg)
                    this.isLoading = false;
                }
            })


        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
        }
    },
    created(){
        axios.get('/api/get_libros_malos').then(res=>{
            this.libros_malos = res.data.libros_malos
            this.cantidad_libros_malos = res.data.cantidad_libros_malos
            console.log(this.libros_malos)
        });
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Libros de Contabilidad</h4>
                        <div class="row mt-4">
                            <div class="col-md-12 mb-3">
                                <div class="card bg-soft-primary">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Desde :" label-for="text" >
                                                    <date-picker v-model="fecha" value-type="YYYY-MM-DD" format="DD-MM-YYYY" :first-day-of-week="2" lang="es" range ></date-picker>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Libro :" label-for="text" >
                                                    <select class="form-control" v-model="tipos_libros">
                                                        <option :value="null">Seleccion marca</option>
                                                        <option value="sin marca">Sin marca</option>
                                                        <option value="sin marca">Sin marca</option>
                                                        <option value="sin marca">Sin marca</option>
                                                        <option value="sin marca">Sin marca</option>
                                                    </select>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-button variant="primary" block @click="buscar()">Buscar</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;libros
                                    </label>
                                    <label >de {{ this.libros.length }}</label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="libros"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                        </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <loading-message v-if="isLoading" />
                        </div>

                        <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label >total de {{ this.cantidad_libros_malos }}</label>
                                </div>
                            </div>
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="libros_malos"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                            <template v-slot:cell(accion)="data">
                                <b-button size="sm" variant="info" @click="showModaleditar(data.item)" >Editar</b-button>
                            </template>
                        </b-table>
                        </div>
                        <b-modal ref="Modificar_Libro" title="Modificar registro" hide-footer size="xl" @hidden="CerrarEditar()">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group id="glosa_id" label-cols-sm="2" label-cols-lg="2" label="glosa" label-for="text">
                                            <p>Ñ ñ</p>
                                            <p>á é í ó ú</p>
                                            <p>Á É Í Ó Ú</p>
                                            <b-form-input id="glosa" for="text" v-model="registro.glosa" maxlength="600" ></b-form-input>
                                            <div class="text-left">
                                                <p v-if="registro.glosa" class="badge position-absolute" :class="{ 'badge-primary': registro.glosa.length !== 60,
                                                'badge-danger': registro.glosa.length ===60 }">
                                                    {{ registro.glosa.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="CerrarEditar()">Volver</b-button>
                                <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarproducto">Modificar</b-button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>