<script>
import HorizontalTopbar from "../../components/horizontal-topbar";
import HorizontalNavInicio from "../../components/horizontal-nav-inicio";
import RightBar from "../../components/right-bar";
import Footer from "../../components/footer2";
    export default {
        components: {
            HorizontalTopbar,
            HorizontalNavInicio,
            RightBar,
            Footer
        },
        props:{
            submiturl: {
            type: String,
            required: true
            },
            autherror: {
            type: String,
            required: false,
            default: () => null
            },
            autenticado:'',
        },
        data(){
            return{

            }
        },
        methods:{
            toggleRightSidebar() {
                document.body.classList.toggle("right-bar-enabled");
            },
            hideRightSidebar() {
                document.body.classList.remove("right-bar-enabled");
            }
        }
    }
</script>
<template>
    <div>
    <!-- Begin page -->
        <div id="layout-wrapper">
            <HorizontalTopbar :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado"/>
            <!--<HorizontalNavInicio />-->
            <!-- ============================================================== -->
            <!-- Start right Content here -->
            <!-- ============================================================== -->
            <div class="main-content2">
                <div class="page-content2">
                    <div class="container-fluid mt-5">
                        <slot />
                    </div>
                    <!-- container-fluid -->
                </div>
                <!-- End Page-content -->
                <Footer />
            </div>
        <!-- end main content-->
        </div>
        <!-- END layout-wrapper -->
        <RightBar/>
    </div>
</template>
<style lang="scss" scoped>

</style>