<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            productos1:[],
            productos2:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "estado",label:"Publicado", sortable: true },
                { key: "destacado",label:"Destacado", sortable: true },
                { key: "nombre",label:"Nombre", sortable: true },
                { key: "stock",label:"Stock", sortable: true },
                { key: "marca",label:"Marca", sortable: true },
                { key: "tipo",label:"Tipo", sortable: true },
                { key: "acciones",label:"Acciones", sortable: true },
            ],
            tipo:'',
            tabIndex: 0,
            tabIndex2: 0,
            tabs: [],
            tabCounter: 0,
            producto_data:{
                tipo:'',
                nombre:'',
                sku:'',
                marca:'',
                desccorta:'',
                desclarga:'',
                categoria:[],
                estado_inventario:'',
                stock:'',
                precio_normal:'',
                precio_rebajado:'',
                archivo:'',
                atributo:[],
                variaciones:[],
                galeria:[],
                imagenesgaleria:[],
            },
            variacion:[],
            marcas:[],
            categorias:[],
            imagenminiatura:'',
            atributo:{
                id:'',
                nombre:'',
                valores:''
            },
            contador:0,
            boton_var:false,
            files:[]

        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.productos1.length;
        },
        imagen(){
            return this.imagenminiatura;
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        closeTab(x) {
            /*this.contador--;
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i] === x) {
                    this.tabs.splice(i, 1)
                    this.producto_data.atributo.splice(i, 1)
                }
            }*/
            this.contador--;
            for (let i = 0; i < this.producto_data.atributo.length; i++) {
                if (this.producto_data.atributo[i].id === x) {
                    this.producto_data.atributo.splice(i, 1)
                }
            }
        },
        
        newTab() {
            /*this.tabs.push(this.tabCounter++);
            let atributo = {
                id:this.tabCounter,
                nombre:'',
                valores:''
            }
            this.producto_data.atributo.push(atributo);
            this.contador++;*/
            //this.producto_data.atributo.push(this.tabCounter++);
            let atributo = {
                id:this.tabCounter++,
                nombre:'',
                valores:''
            }
            this.producto_data.atributo.push(atributo);
            this.contador++;            
        },
        formatNames(files) {
            this.files=files
            this.files.forEach((file) => {
                file.preview = URL.createObjectURL(file);
            });
            //console.log(files)
            return files.length === 1 ? files[0].name : `${files.length} imagenes seleccionadas`
        },
        guardar_productos(){
            let formData = new FormData();
            let cat=[];
            this.producto_data.categoria.forEach(element => {
                cat.push(element.id);
            });
            //campos en comun
            //console.log(this.producto_data.galeria);
            this.producto_data.tipo = this.tipo;
            formData.append('tipo', this.producto_data.tipo);
            formData.append('nombre', this.producto_data.nombre);
            formData.append('sku', this.producto_data.sku);
            formData.append('marca', this.producto_data.marca.id);
            formData.append('desccorta', this.producto_data.desccorta);
            formData.append('desclarga', this.producto_data.desclarga);
            formData.append('categoria', cat.toString());
            formData.append('archivo', this.producto_data.archivo);
            //formData.append('galeria', this.producto_data.galeria);
            /*formData.append('imagenesgaleria', this.producto_data.imagenesgaleria);
            formData.append('totalimagenes',this.producto_data.galeria.length);
            this.producto_data.galeria.forEach((file, index) => {
                formData.append(`imagen_${index}`, file);
            });*/
            this.files.forEach((file, index) => {
                formData.append('images'+index, file);               
            });
            console.log(formData)

            //campos producto simple
            formData.append('estado_inventario', this.producto_data.estado_inventario);
            formData.append('stock', this.producto_data.stock);
            formData.append('precio_normal', this.producto_data.precio_normal);
            formData.append('precio_rebajado', this.producto_data.precio_rebajado);
            //campos producto variable
            formData.append('atributo', JSON.stringify(this.producto_data.atributo));
            formData.append('variaciones', JSON.stringify(this.producto_data.todas_variaciones));
            axios.post('/api/guardar_productos_web',formData).then(res=>{
                if (res.data.estado) {
                    axios.get('/api/get_productos_web').then(res=>{
                        this.productos1 = res.data.productos;
                    })
                    console.log(res.data.mensaje);
                }else{
                    console.log(res.data.mensaje);
                }
            })
        },
        labelselect_cat ({ nombre }) {
            return `${nombre}`
        },
        obtenerImagen(e){
            let file = e.target.files[0];
            this.producto_data.archivo = file;
            //this.producto_data.galeria = file;
            this.cargarImagen(file);
        },
        cargarImagen(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.imagenminiatura = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        publicar(id,valor){
            let formData = new FormData();
            formData.append('producto_id', id);
            formData.append('valor', valor);
            axios.post('/api/estado_producto',formData).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
        },
        destacado(id,valor){
            let formData = new FormData();
            formData.append('producto_id', id);
            formData.append('valor', valor);
            axios.post('/api/destacar_producto',formData).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                }
            });
        },
        eliminar(data){
            axios.post('/api/eliminar_productoweb',data).then(res=>{
                if(res.data.estado){ 
                    console.log(res.data.mensaje);
                    axios.get('/api/get_productos_web').then(res=>{
                        this.productos1 = res.data.productos;
                        //console.table(this.productos);
                    })
                }else{
                    console.log(res.data.mensaje);
                }
            });
        },
        generar_variaciones(){
            if(this.producto_data.atributo.length>=1 && (this.producto_data.atributo[0].nombre!='' && this.producto_data.atributo[0].valores!='')){
                this.boton_var=true;
                //generamos variaciones
                this.producto_data.atributo.forEach(element => {
                    element.valores_array = element.valores.split('|');
                });
                let variaciones=[];
                const generarCombinaciones = (index, combinacionActual) => {
                    if (index === this.producto_data.atributo.length) {
                        combinacionActual.combinacionString = Object.values(combinacionActual).join('-');
                        variaciones.push(combinacionActual);
                        return;
                    }
                    let atributo = this.producto_data.atributo[index];
                    let valores = atributo.valores_array;
                    for (let valor of valores) {
                        let nuevaCombinacion = { ...combinacionActual };
                        nuevaCombinacion[atributo.id] = valor;
                        generarCombinaciones(index + 1, nuevaCombinacion);
                    }
                };
                generarCombinaciones(0, {});
                this.producto_data.variaciones = variaciones;
                
                this.generar_arreglo();
            }else{
                this.boton_var=false;
                //no se puede generar porq no hay atributos creados
            }
        },
        generar_arreglo(){
            this.producto_data.todas_variaciones=[];
            for (let index = 0; index < this.producto_data.variaciones.length; index++) {
                let vari = {
                    id:index,
                    precio_normal:'',
                    precio_rebajado:'',
                    estado_inventario:'',
                    stock:'',
                    titulo:this.producto_data.variaciones[index].combinacionString,
                    variacion:this.producto_data.variaciones[index]
                }
                this.producto_data.todas_variaciones.push(vari);
            }
        },
        // formatNames(files) {
        //     return files.length === 1 ? files[0].name : `${files.length} imagenes seleccionadas`
        // },
        obtenerGaleria(e){
            //console.log(this.files)
            // this.files = [];
            // this.producto_data.imagenesgaleria=[];
            // for (let i = 0; i < this.producto_data.galeria.length; i++) {
            //     let file = this.producto_data.galeria[i];
            //     let file1 = e.target.files[i];
            //     this.producto_data.imagenesgaleria.push(file1);
            //     let reader = new FileReader();
            //     reader.onload = (e) => {
            //     this.files.push({
            //         name: file.name,
            //         url: e.target.result
            //     });
            //     };
            //     reader.readAsDataURL(file);
            // }
            /*this.producto_data.imagenesgaleria=[];
            for (let i = 0; i < this.producto_data.galeria.length; i++) {
                const file = this.producto_data.galeria[i];
                this.cargarGaleria(file);
            }*/
            
        },
        /*cargarGaleria(file){
            let reader = new FileReader(); 
            reader.onload = (e) =>{
                this.producto_data.galeria.push(e.target.result);
            }
            reader.readAsDataURL(file);
        },*/
    },
    created(){
        axios.get('/api/get_productos_web').then(res=>{
            this.productos1 = res.data.productos;
            //console.table(this.productos);
        })
        axios.get('/api/get_marcas').then(res=>{
            this.marcas = res.data.marcas;
            //console.table(this.productos);
        })
        axios.get('/api/get_categorias').then(res=>{
            this.categorias = res.data.categorias;
            //console.table(this.productos);
        })
        let atributo = {
            id:this.tabCounter++,
            nombre:'',
            valores:''
        }
        this.producto_data.atributo.push(atributo);
        this.contador++;
    }
    
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Productos</h4>
                        <div class="row mt-4">
                            <div class="col-md-12 text-right mb-3">
                                <b-button variant="primary" size="sm" v-b-modal.modal-1>Cargar producto</b-button>
                                <b-modal id="modal-1" title="Cargar Producto" size="lg" ok-title="Agregar" cancel-title="Cancelar" @ok="guardar_productos">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Tipo de Producto" label-for="text" >
                                                <select class="form-control" v-model="tipo">
                                                    <option value="">Selecciona una opción</option>
                                                    <option value="Simple">Producto Simple</option>
                                                    <option value="Variable">Producto Variable</option>
                                                </select> 
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-tabs content-class="mt-3" v-if="tipo!=''">
                                        <b-tab title="Producto">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Nombre:" label-for="text">
                                                        <b-form-input for="text" placeholder="Nombre del producto" v-model="producto_data.nombre"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="SKU:" label-for="text">
                                                        <b-form-input for="text" placeholder="Código del producto" v-model="producto_data.sku"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Marca:" label-for="text" >
                                                        <multiselect
                                                            v-model="producto_data.marca"
                                                            :options="marcas"
                                                            :custom-label="labelselect_cat" 
                                                            placeholder="Seleccionar categorias" 
                                                            selectLabel="Presiona para seleccionar"
                                                            selectedLabel="Seleccionado"
                                                            deselectLabel="Presiona para remover"
                                                            track-by="nombre"
                                                            >
                                                            <template v-slot:noOptions>
                                                                <p>No hay opciones</p>
                                                            </template>
                                                            <template v-slot:noResult>
                                                                <p>No se encuentra coincidencia</p>
                                                            </template>
                                                        </multiselect>
                                                        <!-- <select class="form-control" v-model="producto_data.marca_id">
                                                            <option :value="''">Seleccion marca</option>
                                                            <option value="sin marca">Sin marca</option>
                                                            <option v-for="item in marcas" :key="item.id">{{item.nombre}} </option>
                                                        </select>  -->
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción corta:" label-for="text">
                                                        <b-form-input for="text" placeholder="Descripción corta del producto" v-model="producto_data.desccorta"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Descripción Larga:" label-for="text">
                                                        <b-form-textarea for="text" placeholder="Descripción larga del producto" v-model="producto_data.desclarga" rows="3" max-rows="6"></b-form-textarea>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Estado del inventario:" label-for="text" v-if="tipo=='Simple'">
                                                        <b-form-radio v-model="producto_data.estado_inventario" name="some-radios" value="hay">Hay Existencias</b-form-radio>
                                                        <b-form-radio v-model="producto_data.estado_inventario" name="some-radios" value="no hay">Sin Existencias</b-form-radio>
                                                        <b-form-radio v-model="producto_data.estado_inventario" name="some-radios" value="reservar">Se puede reservar</b-form-radio>
                                                    </b-form-group>
                                                    <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cantidad disponibles:" label-for="text" v-if="tipo=='Simple' && producto_data.estado_inventario=='hay'">
                                                        <b-form-input for="text" placeholder="Unidades disponibles para comprar" v-model="producto_data.stock"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Precio" v-if="tipo=='Simple'">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                        <b-form-input for="text" placeholder="" v-model="producto_data.precio_normal"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                        <b-form-input for="text" placeholder="" v-model="producto_data.precio_rebajado"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Imagen Principal" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                                <b-form-file
                                                v-model="producto_data.imagen"
                                                accept="image/*"
                                                placeholder="Elegir Archivo"
                                                drop-placeholder="Drop file here..."
                                                @change="obtenerImagen"
                                                ></b-form-file>
                                            </b-form-group>
                                            <div v-if="producto_data.imagen!=null">
                                                <p>Previsualización de imagen</p>
                                                <div class="text-center" >
                                                    <figure>
                                                        <img :src="imagen" height="200" alt="Imagen del Taller">
                                                    </figure>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Categoria" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="4" label="Categorias :" label-for="text">
                                                <!-- <multiselect v-model="producto_data.categoria" :options="categorias" :custom-label="labelselect_cat" placeholder="Select one" label="nombre" track-by="nombre"></multiselect> -->
                                                <multiselect
                                                    v-model="producto_data.categoria"
                                                    :options="categorias" 
                                                    :multiple="true"
                                                    :custom-label="labelselect_cat" 
                                                    placeholder="Seleccionar categorias" 
                                                    selectLabel="Presiona para seleccionar"
                                                    selectedLabel="Seleccionado"
                                                    deselectLabel="Presiona para remover"
                                                    track-by="nombre"
                                                    >
                                                    <template v-slot:noOptions>
                                                        <p>No hay opciones</p>
                                                    </template>
                                                    <template v-slot:noResult>
                                                        <p>No se encuentra coincidencia</p>
                                                    </template>
                                                </multiselect>
                                            </b-form-group>
                                        </b-tab>
                                        <b-tab title="Atributos" v-if="tipo=='Variable'" >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-card no-body>
                                                        <b-tabs card v-model="tabIndex">
                                                            <!-- Render Tabs, supply a unique `key` to each tab -->
                                                            <b-tab v-for="(i,index) in producto_data.atributo" :key="'dyn-tab-' + index" :title="'Atributo ' + (index+1)">
                                                                <div class="row">
                                                                    <div class="col-md-12 mb-3">
                                                                        <b-button size="sm" variant="danger" class="float-right" @click="closeTab(index)">Cerrar</b-button>
                                                                    </div>
                                                                    <div class="col-md-5">
                                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Nombre:" label-for="text">
                                                                            <b-form-input for="text" placeholder="Por ejemplo, la talla o el color" v-model="i.nombre"></b-form-input>
                                                                        </b-form-group>
                                                                    </div>
                                                                    <div class="col-md-7">
                                                                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Valores:" label-for="text">
                                                                            <b-form-textarea for="text" placeholder="Introduce las opciones entre las que podrán elegir los clientes, por ejemplo, 'Azul' o 'Talla M'. Utiliza '|' para separar las distintas opciones" v-model="i.valores" rows="3" max-rows="6"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </div>
                                                                </div>
                                                            </b-tab>
                                                            <!-- New Tab Button (Using tabs-end slot) -->
                                                            <template #tabs-end>
                                                                <b-nav-item role="presentation" @click.prevent="newTab" href="#"><b>Agregar Nuevo</b></b-nav-item>
                                                            </template>
                                                            <!-- Render this if no tabs -->
                                                            <template #empty>
                                                                <div class="text-center text-muted">
                                                                No hay atributos agregados<br>
                                                                Agregue uno presionando "Agregar nuevo"
                                                                </div>
                                                            </template>
                                                        </b-tabs>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Variaciones" v-if="tipo=='Variable'">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <b-button variant="primary" size="sm" class="float-right" @click="generar_variaciones">Generar Variaciones</b-button>
                                                </div>
                                                <div class="col-md-12" v-if="boton_var">
                                                    <b-tabs content-class="mt-3" pills card vertical v-model="tabIndex2">
                                                        <b-tab :title="variacion.titulo" v-for="(variacion,index) in producto_data.todas_variaciones" :key="index" >
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Normal:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.precio_normal"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Precio Rebajado:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.precio_rebajado"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="6" label-cols-lg="6" label="Estado del inventario:" label-for="text" class="text-left">
                                                                        <b-form-radio v-model="variacion.estado_inventario" name="some-radios" value="hay">Hay Existencias</b-form-radio>
                                                                        <b-form-radio v-model="variacion.estado_inventario" name="some-radios" value="no hay">Sin Existencias</b-form-radio>
                                                                        <b-form-radio v-model="variacion.estado_inventario" name="some-radios" value="reservar">Se puede reservar</b-form-radio>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Cantidad unidades:" label-for="text">
                                                                        <b-form-input for="text" placeholder="" v-model="variacion.stock"></b-form-input>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </b-tab>
                                                    </b-tabs>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Galeria" >
                                            <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cargar Imagen:" label-for="text">
                                                <b-form-file
                                                multiple
                                                v-model="producto_data.galeria"
                                                accept="image/*"
                                                placeholder="Elegir Archivo"
                                                drop-placeholder="Drop file here..."
                                                @change="obtenerGaleria"
                                                :file-name-formatter="formatNames"
                                                browse-text="Examinar"
                                                ></b-form-file>
                                            </b-form-group>
                                            <div v-if="files.length > 0">
                                                <h2>Imágenes seleccionadas:</h2>
                                                <div v-for="(file, index) in files" :key="index">
                                                    <img :src="file.preview" alt="Imagen seleccionada" style="max-width: 200px; max-height: 200px; margin: 10px;">
                                                </div>
                                            </div>
                                            <!-- <div v-if="producto_data.imagenesgaleria.length > 0">
                                                <p>Previsualización de imagen</p>
                                                <div v-for="(preview, index) in producto_data.imagenesgaleria" :key="index">
                                                    <img :src="preview" alt="Preview" style="max-width: 100%;" />
                                                </div>
                                            </div> -->
                                        </b-tab>
                                    </b-tabs>
                                </b-modal>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;productos
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="productos1"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(estado)="data">
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox :id="'estado'+data.item.id" v-model="data.item.estado" :name="'estado'+data.item.id" :value="1" :unchecked-value="0" @change="publicar(data.item.id, data.item.estado)"> </b-form-checkbox>
                                    </b-form-group>
                                </template>
                                <template v-slot:cell(destacado)="data">
                                    <b-form-group id="example text" label-for="text">
                                        <b-form-checkbox :id="'destacado'+data.item.id" v-model="data.item.destacado" :name="'destacado'+data.item.id" :value="1" :unchecked-value="0" @change="destacado(data.item.id, data.item.destacado)"> </b-form-checkbox>
                                    </b-form-group>
                                </template>
                                <template v-slot:cell(stock)="data">
                                    <p v-if="data.item.tipo=='Simple'">{{ data.item.stock }}</p>
                                    <p v-if="data.item.tipo=='Variable'">{{ data.item.suma_stock }}</p>
                                </template>
                                <template v-slot:cell(marca)="data">
                                    {{ data.item.nombre_marca }}
                                </template>
                                <template v-slot:cell(acciones)="data">
                                    <b-button size="sm" variant="danger" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button>
                                    <!-- <b-button size="sm" variant="primary"><i class="bx bx-pencil"></i></b-button> -->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>