<script>
/**
 * Carousel component
 */
export default {
    props:{
        slides:[],
    },
    data() {
        return {
            slide: 0,
            slide1: 0,
            sliding: null,
        }
    },
}
</script>

<template>
    <div class="col-lg-12">
        <b-carousel controls indicators img-width="1024" img-height="480">
            <b-carousel-slide :img-src="slide.image" v-for="slide in slides" :key="slide.id"></b-carousel-slide>
        </b-carousel>
    </div>
    <!-- end col -->
</template>