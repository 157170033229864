import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cart: []
    },
    mutations: {
        addToCart(state, product) {
            const existingProduct = state.cart.find(item => item.producto_id === product.producto_id);
            if (existingProduct) {
                if ((existingProduct.cantidad + product.cantidad)<product.stock ) {
                    existingProduct.cantidad = existingProduct.cantidad + product.cantidad;
                }else{
                    existingProduct.cantidad = product.stock;
                }
            }else{
                state.cart.push(product);
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        removeFromCart(state, index) {
            state.cart.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        increaseQuantity(state, index) {
            if (state.cart[index].cantidad<state.cart[index].stock) {
                state.cart[index].cantidad++;
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        decreaseQuantity(state, index) {
            if (state.cart[index].cantidad > 1) {
                state.cart[index].cantidad--;
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        initializeStore(state) {
            if (localStorage.getItem('cart')) {
                state.cart = JSON.parse(localStorage.getItem('cart'));
            }
        }
    },
    actions: {
        addToCart(context, product) {
            context.commit('addToCart', product);
        },
        removeFromCart(context, index) {
            context.commit('removeFromCart', index);
        },
        increaseQuantity(context, index) {
            context.commit('increaseQuantity', index);
        },
        decreaseQuantity(context, index) {
            context.commit('decreaseQuantity', index);
        },
        initializeStore(context) {
            context.commit('initializeStore');
        }
    },
    getters: {
        cartItems: state => state.cart,
        cartTotal: state => {
            return state.cart.reduce((total, item) => total + (item.precio*item.cantidad), 0);
        }
    }
});