<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import moment from "moment";
/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader, DatePicker },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            desde:'',
            hasta:'',
            titulos:[],
            linewithDataChart1:{}, 
            linewithDataChart2:{}, 
            adjudicadas:[],
            noadjudicadas:[],
            montoadj:[],
            montonoadj:[],
            periodo:'',
            productos:[],
            productos2:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "count",
            sortDesc: true,
            fields: [
                { key: "num", label:"Nº",sortable: true },
                { key: "codigo", label:"Código",sortable: true },
                { key: "nombre", label:"Producto",sortable: true },
                { key: "marca", label:"Marca",sortable: true },
                { key: "count", label:"Cantidad",sortable: true },
            ],
            totalRows2: 1,
            currentPage2: 1,
            perPage2: 10,
            pageOptions2: [10, 25, 50, 100],
            filter2: null,
            filterOn2: [],
            sortBy2: "licittotal",
            sortDesc2: true,
            fields2: [
                { key: "num", label:"Nº",sortable: true },
                { key: "codigo", label:"Código",sortable: true },
                { key: "nombre", label:"Producto",sortable: true },
                { key: "marca", label:"Marca",sortable: true },
                { key: "licittotal", label:"Cantidad",sortable: true },
            ]
        };
    },
    methods:{
        deshabilitar(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today ;
        },
        mostrarmes(){
            if (this.desde==''){
                return this.$toasted.error('Falta seleccionar Desde');
            }
            if (this.hasta==''){
                return this.$toasted.error('Falta seleccionar Hasta');
            }
            this.titulos='';
            this.adjudicadas='';
            this.noadjudicadas='';
            this.montoadj='';
            axios.get('/api/grafico/'+this.desde+'/'+this.hasta).then(res=>{
            this.titulos=res.data.labels;
            this.adjudicadas=res.data.adjudicadas;
            this.noadjudicadas=res.data.noadjudicadas;
            this.montoadj=res.data.montoadj;
            this.montonoadj=res.data.montonoadj;
            this.productos=res.data.productostodos;
            this.productos2=res.data.productostodos;
            this.periodo= this.titulos.length+' meses';
            this.grafico(this.periodo);
        });
        },
        grafico(periodo){
            //console.log(titulos);
            this.linewithDataChart1 = {
                chartOptions: {
                    chart: {
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#34c38f', '#EF5350'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        width: [3, 3],
                        curve: 'straight'
                    },
                    title: {
                        text: 'Licitaciones durante '+periodo,
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.2
                        },
                        borderColor: '#f1f1f1'
                    },
                    markers: {
                        style: 'inverted',
                        size: 6
                    },
                    xaxis: {
                        categories: this.titulos,//[ "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", "Enero", "Febrero", "Marzo" ],
                        title: {
                            text: 'Periodo'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Cantidad de Licitaciones'
                        },
                        /*min: 5,
                        max: */
                    },
                    /*legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    },*/
                    responsive: [{
                        breakpoint: 600,
                        options: {
                            chart: {
                                toolbar: {
                                    show: false
                                }
                            },
                            legend: {
                                show: false
                            },
                        }
                    }],
                },
                series: [
                    {
                        name: "Licitaciones Adjudicadas",
                        data: this.adjudicadas//[ 0, 0, 0, 0, 0, 0, 6, 258, 349, 283, 230, 28 ]
                    },
                    {
                        name: "Licitaciones no adjudicadas",
                        data:  this.noadjudicadas//[ 0, 0, 0, 0, 0, 0, 0, 303, 395, 297, 242, 29 ] 
                    },
                ],
            };
            this.linewithDataChart2 = {
                chartOptions: {
                    chart: {
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#34c38f', '#EF5350'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        width: [3, 3],
                        curve: 'straight'
                    },
                    title: {
                        text: 'Licitaciones durante '+periodo,
                        align: 'left'
                    },
                    grid: {
                        row: {
                            colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.2
                        },
                        borderColor: '#f1f1f1'
                    },
                    markers: {
                        style: 'inverted',
                        size: 6
                    },
                    xaxis: {
                        categories: this.titulos,//[ "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre", "Enero", "Febrero", "Marzo" ],
                        title: {
                            text: 'Periodo'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Monto'
                        },
                        /*min: 5,
                        max: */
                    },
                    /*legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    },*/
                    responsive: [{
                        breakpoint: 600,
                        options: {
                            chart: {
                                toolbar: {
                                    show: false
                                }
                            },
                            legend: {
                                show: false
                            },
                        }
                    }],
                },
                series: [
                    {
                        name: "Licitaciones Adjudicadas",
                        data: this.montoadj//[ 0, 0, 0, 0, 0, 0, 6, 258, 349, 283, 230, 28 ]
                    },
                    {
                        name: "Licitaciones no adjudicadas",
                        data:  this.montonoadj//[ 0, 0, 0, 0, 0, 0, 0, 303, 395, 297, 242, 29 ] 
                    },
                ],
            };
            
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFiltered2(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows2 = filteredItems.length;
            this.currentPage2 = 1;
        }
    },
    created(){
        this.periodo='los últimos 6 meses';
        this.hasta= moment(new Date).format('YYYY-MM-DD');
        var d= new Date();
        this.desde= d.setMonth(d.getMonth()-5);
        d = new Date(this.desde);
        this.desde = d.setDate(1);
        this.desde = moment(new Date(this.desde)).format('YYYY-MM-DD');
        axios.get('/api/grafico/'+this.desde+'/'+this.hasta).then(res=>{
            this.titulos=res.data.labels;
            this.adjudicadas=res.data.adjudicadas;
            this.noadjudicadas=res.data.noadjudicadas;
            this.montoadj=res.data.montoadj;
            this.montonoadj=res.data.montonoadj;
            this.productos=res.data.productostodos;
            this.productos2=res.data.productostodos;
            this.grafico(this.periodo);
        });
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
        return this.productos.length;
        },
        rows2() {
        return this.productos2.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.totalRows2 = this.items.length;
    },
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="row pr-1">
                            <div class="col-12">
                                <h4 class="card-title">Análisis</h4>
                                <div class="row mt-4">
                                    <div class="col-md-5">
                                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Desde :" label-for="text" >
                                            <date-picker v-model="desde" value-type="YYYY-MM-DD" format="DD-MM-YYYY" :first-day-of-week="2" lang="es" :disabled-date="deshabilitar"></date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-5">
                                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Hasta :" label-for="text" >
                                            <date-picker v-model="hasta" value-type="YYYY-MM-DD" format="DD-MM-YYYY" :first-day-of-week="2" lang="es" :disabled-date="deshabilitar"></date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-2">
                                        <b-button variant="primary" block class="mb-4" @click="mostrarmes">Ver</b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-body" dir="ltr">
                                        <apexchart
                                        class="apex-charts"
                                        height="380"
                                        type="line"
                                        :series="linewithDataChart1.series"
                                        :options="linewithDataChart1.chartOptions"
                                        ></apexchart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-body" dir="ltr">
                                        <apexchart
                                        class="apex-charts"
                                        height="380"
                                        type="line"
                                        :series="linewithDataChart2.series"
                                        :options="linewithDataChart2.chartOptions"
                                        ></apexchart>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-5">
                                <h4>Productos en más licitaciones</h4>
                                <label>Cantidad de productos: {{productos.length}}</label>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Mostrar&nbsp;
                                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;productos
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Buscar:
                                                <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Buscar..."
                                                class="form-control form-control-sm ml-2"
                                                ></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                    <!-- Table -->
                                <div class="table-responsive mb-0 ">
                                    <b-table
                                        hover
                                        :items="productos"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                    >
                                        <template v-slot:cell(num)="data">
                                            {{data.index+1}}
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 p-5">
                                <h4>Productos más licitados</h4>
                                <label>Cantidad de productos: {{productos2.length}}</label>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Mostrar&nbsp;
                                                <b-form-select v-model="perPage2" size="sm" :options="pageOptions2"></b-form-select>&nbsp;productos
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Buscar:
                                                <b-form-input
                                                v-model="filter2"
                                                type="search"
                                                placeholder="Buscar..."
                                                class="form-control form-control-sm ml-2"
                                                ></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>
                                    <!-- Table -->
                                <div class="table-responsive mb-0">
                                    <b-table
                                        hover
                                        :items="productos2"
                                        :fields="fields2"
                                        responsive="sm"
                                        :per-page="perPage2"
                                        :current-page="currentPage2"
                                        :sort-by.sync="sortBy2"
                                        :sort-desc.sync="sortDesc2"
                                        :filter="filter2"
                                        :filter-included-fields="filterOn2"
                                        @filtered="onFiltered2"
                                    >
                                        <template v-slot:cell(num)="data">
                                            {{data.index+1}}
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage2" :total-rows="rows2" :per-page="perPage2"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>