<script>
import Layout from "./base";
import Carousel from "../componentes/carousel";
import Productos from "../componentes/productos";
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
/**
 * Starter component
 */
export default {
    components: { 
        Layout, 
        Carousel, 
        Productos, 
        Hooper,
        Slide
    },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:''
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            promociones:[],
            slides:[],
            slidesizq:[],
            slidesder:[],
            ofertas:[],
        };
    },
    computed:{
        style_slide(){
            if (this.slides.lenght>1) {
                return 'width:50%;height:auto;'
            }
            else{
                return 'width:100%;height:auto;'
            }
        }
    },
    created(){
        axios.get('/api/get_slide_publicadas').then(res=>{
            this.slides=res.data.slides;
            console.log(this.slides);
        });
        axios.get('/api/get_slide_publicadasizq').then(res=>{
            this.slidesizq=res.data.slides;
        });
        axios.get('/api/get_slide_publicadasder').then(res=>{
            this.slidesder=res.data.slides;
        });
        axios.get('/api/get_productos_publicados_vista').then(res=>{
            this.promociones=res.data.productos;
        });
        axios.get('/api/get_productos_web_oferta').then(res=>{
            this.ofertas=res.data.productos;
            //console.log(this.promociones);
        });
    }
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div class="row">
            <Carousel class="pb-5" :slides="slides" v-if="slides.length>0"/>
        </div>
        <div class="row">
            <div class="col-md-6 cont mb-3" v-if="slidesizq!=null">
                <img :src="slidesizq.image" alt class="cont_img" />
            </div>
            <div class="col-md-6 cont mb-3" v-if="slidesder!=null">
                <img :src="slidesder.image" alt class="cont_img"  />
            </div>
        </div>
        <div class="row" v-if="promociones.length>0">
            <Productos :titulo="'PRODUCTOS RECOMENDADOS'" :productos="promociones"/>
        </div>
        <!-- <div class="row" v-if="ofertas.length>0">
            <Productos :titulo="'OFERTAS'" :productos="ofertas"/>
        </div> -->
    </Layout>
</template>

<style>
.cont{
    width: 200px;
    height: 300px;
}
.cont_img{
    object-fit: cover;
    width:100%;
    height:100%;
}
@media (max-width: 992px) {
    .cont{
        width: 100%;
        height: 100%;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
}
</style>