<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import VueResponsiveImage from 'vue-responsive-image'
import VueHtml2pdf from 'vue-html2pdf';
import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun, Media,ImageRun } 
from "docx";
const FileSaver = require("file-saver");
export default {
  
    components: { Layout, PageHeader,Swal,VueResponsiveImage,VueHtml2pdf,Document, Paragraph, Packer, TextRun, saveAs, BorderStyle, WidthType,Media,ImageRun},
    data() {
        return {
            state: {
                name: 'San Luis Potosi',
                map: 'data:image/png;base64',
                municipalities: [
                {name:'San Luis Potosi', population: 824000}, 
                {name:'Rio Verde', population: 160000},
                {name:'Cd Valles', population: 176000},
                {name:'Matehuala', population:82726}
                ],
                tourist_attractions: [
                'Tamtoc', 'Sótano de las Golondrinas', 'Cascada de Tamul' 
                ]
            },
            tableData: [],
            title: "vista producto",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                 active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "codigo",label:'Código', sortable: true },
                { key: "nombre",label:'Nombre', sortable: true },
                { key: "marca", label:'Marca',sortable: true },
                { key: "fabricacion",label:'Fabricación', sortable: true },
                { key: "vencimiento",label:'Vencimiento', sortable: true },
                //{ key: "duracion",label:'Duración', sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            productos:[],
            productos_repetidos:[],
            productosRepetidos:[],
            Componentes_Producto:{
                id:'',
                codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                imagen:null,
                dir:null,
            },
            marcas:'',
            imagenminiatura:'',
            paises:'',
            checked:false,
            showbutton_crearproducto:false,
            showbutton_modificarproducto:false
        };
    },
    computed: {
        rows() {
            return this.productos.length;
        },
        imagen(){
        return this.imagenminiatura;
        }
    },
    watch: {
        checked: function(value,Oldvalue){
            //console.log(value);
            //console.log(Oldvalue);
            if(value){
                this.Componentes_Producto.vencimiento='NO APLICA'
                //console.log(this.Componentes_Producto.vencimiento);
            }else{
                this.Componentes_Producto.vencimiento=null
            }
        }
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        camposmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            }).then(()=>{
                window.location.href = `/productos`;
            });
        },
        validacionmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f"
            });
        },
        eliminarmsg(text) {
            Swal.fire({
                icon: 'success',
                title: "Producto eliminado correctamente",
                text: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver"
            }).then(()=>{
                window.location.href = `/productos`;
            });;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        showModalver(data) {
            console.log(data);
            let regEx3 = new RegExp('\\r','g')
            let datacaracteristica = data.caracteristicas.replace(regEx3,'\n')
            this.Componentes_Producto=data
            this.Componentes_Producto.caracteristicas=datacaracteristica,
                // {
                // // id:data.id,
                // // codigo:data.codigo,
                // // nombre:data.nombre,
                // //descripcion:data.descripcion,
                // // marca:data.marca,
                // // fabricacion:data.fabricacion,
                // // vencimiento:data.vencimiento,
                // //duracion:data.duracion,
                // // certificacion:data.certificacion,
                // // presentacion:data.presentacion,
                // //envio:data.envio,
                // //plazoentrega:data.plazoentrega,
                // caracteristicas:datacaracteristica,
                // // dir:data.foto_dir,
                // };
            //console.log(this.Componentes_Producto);
            
            //this.Componentes_Producto.caracteristicas.replace('\\r','@')
            // console.log(this.Componentes_Producto.caracteristicas);
            this.$refs['modal-ver'].show();
            //console.log(data);
        },
        showModaleditar(data){
            this.$refs['Modificar_Producto'].show();
            this.Componentes_Producto=data
            this.Componentes_Producto.vencimiento=data.vencimiento.split('-').reverse().join('-')
            // vencimiento:data.vencimiento.split('-').reverse().join('-'),
            //     {
            //     id:data.id,
            //     codigo:data.codigo,
            //     nombre:data.nombre,
            //     //descripcion:data.descripcion,
            //     marca:data.marca,
            //     fabricacion:data.fabricacion,
            //     //duracion:data.duracion,
            //     certificacion:data.certificacion,
            //     presentacion:data.presentacion,
            //     //envio:data.envio,
            //     //plazoentrega:data.plazoentrega,
            //     caracteristicas:data.caracteristicas,
            //     foto_dir_resize:data.foto_dir_resize,
            //     foto_dir:data.foto_dir,
            //     };
            //console.log(data);
            // console.log(this.Componentes_Producto);
        },
        CrearProducto(){
            this.Componentes_Producto=
                            {codigo:null,
                            nombre:null,
                            //descripcion:null,
                            marca:null,
                            fabricacion:null,
                            vencimiento:null,
                            //duracion:null,
                            certificacion:null,
                            presentacion:null,
                            //envio:null,
                            //plazoentrega:null,
                            caracteristicas:null,
                            };
            this.$refs['Crear_Producto'].show();
        },
        Crear(){
            this.showbutton_crearproducto=true
            let final_caracteristicas=0
            if(this.Componentes_Producto.caracteristicas!= '' || this.Componentes_Producto.caracteristicas!= null || this.Componentes_Producto.caracteristicas!= 'null'){
                let regEx = new RegExp('\\n','g')
                let texto_lineas = this.Componentes_Producto.caracteristicas
                let valor_amostrar = this.Componentes_Producto.caracteristicas
                let a = 0
                //contar saltos de linea
                let valor_propiedad = this.Componentes_Producto.caracteristicas.replace(regEx,'@')
                for (let i = 0; i < valor_propiedad.length; i++) {
                    if(valor_propiedad.charAt(i) ==='@'){
                        a = a +1
                    }
                    
                }
                //contar lineas
                let z = 0
                let regEx2 = new RegExp('\\s+','g')
                let valor_propiedad_2 = texto_lineas.replace(regEx2,' ')
                final_caracteristicas = valor_propiedad_2.length
                z = Math.trunc(parseInt(final_caracteristicas)/104)
                let y=a+z
                //console.log(y);
                if(y>14){
                    //console.log('entramos al alert')
                    this.showbutton_crearproducto=false
                    return this.validacionmsg('Máximo de lineas alcanzado','warning');
                }else {
                    //console.log('entramos al seteo')
                    this.Componentes_Producto.caracteristicas = valor_amostrar
                    if(this.Componentes_Producto.codigo == null || this.Componentes_Producto.nombre == null || this.Componentes_Producto.imagen == null || this.Componentes_Producto.nombre == ''|| this.Componentes_Producto.codigo == ''){
                        this.showbutton_crearproducto=false
                        return this.validacionmsg('No puede haber campos vacios','warning');
                    }else{
                        let formData = new FormData();
                        formData.append('id', this.Componentes_Producto.id);
                        formData.append('codigo', this.Componentes_Producto.codigo);
                        formData.append('nombre', this.Componentes_Producto.nombre);
                        //formData.append('descripcion', this.Componentes_Producto.descripcion);
                        formData.append('marca', this.Componentes_Producto.marca);
                        formData.append('fabricacion', this.Componentes_Producto.fabricacion);
                        formData.append('vencimiento', this.Componentes_Producto.vencimiento.split('-').reverse().join('-'));
                        //formData.append('duracion', this.Componentes_Producto.duracion);
                        formData.append('certificacion', this.Componentes_Producto.certificacion);
                        formData.append('presentacion', this.Componentes_Producto.presentacion);
                        //formData.append('envio', this.Componentes_Producto.envio);
                        formData.append('img', this.Componentes_Producto.imagen);
                        //formData.append('plazoentrega', this.Componentes_Producto.plazoentrega);
                        formData.append('caracteristicas', this.Componentes_Producto.caracteristicas);


                        axios.post('/api/crear_productos',formData).then(res=>{
                            if(res.data.estado){
                                this.camposmsg(res.data.mensaje,res.data.icon);
                                this.Componentes_Producto=
                                    {codigo:null,
                                    nombre:null,
                                    //descripcion:null,
                                    marca:null,
                                    fabricacion:null,
                                    vencimiento:null,
                                    //duracion:null,
                                    certificacion:null,
                                    presentacion:null,
                                    //envio:null,
                                    //plazoentrega:null,
                                    caracteristicas:null,
                                    dir:res.data.dir, 
                                    };        
                                console.log(this.Componentes_Producto.dir)
                            }
                        })
                        
                    }
                }   
            }
            
        },
        Modificar(){
            this.showbutton_modificarproducto=true
            let final_caracteristicas=0
            if(this.Componentes_Producto.caracteristicas!= '' || this.Componentes_Producto.caracteristicas!= null || this.Componentes_Producto.caracteristicas!= 'null'){
                let regEx = new RegExp('\\n','g')
                let texto_lineas = this.Componentes_Producto.caracteristicas
                let valor_amostrar = this.Componentes_Producto.caracteristicas
                let a = 0
                //contar saltos de linea
                let valor_propiedad = this.Componentes_Producto.caracteristicas.replace(regEx,'@')
                for (let i = 0; i < valor_propiedad.length; i++) {
                    if(valor_propiedad.charAt(i) ==='@'){
                        a = a +1
                    }
                    
                }
                //contar lineas
                let z = 0
                let regEx2 = new RegExp('\\s+','g')
                let valor_propiedad_2 = texto_lineas.replace(regEx2,' ')
                final_caracteristicas = valor_propiedad_2.length
                z = Math.trunc(parseInt(final_caracteristicas)/104)
                let y=a+z
                //console.log(y);
                if(y>14){
                    //console.log('entramos al alert')
                    this.showbutton_modificarproducto=false
                    return this.validacionmsg('Máximo de lineas alcanzado','warning');
                }else{
                    this.Componentes_Producto.caracteristicas = valor_amostrar
                    if(this.Componentes_Producto.codigo == '' || this.Componentes_Producto.nombre == '' /*|| this.Componentes_Producto.descripcion == ''*/ || 
                    this.Componentes_Producto.marca == '' || this.Componentes_Producto.fabricacion == '' || this.Componentes_Producto.vencimiento == '' /*||
                    this.Componentes_Producto.duracion == ''*/ || this.Componentes_Producto.certificacion == '' || this.Componentes_Producto.presentacion == '' /*||
                    this.Componentes_Producto.envio == '' || this.Componentes_Producto.plazoentrega == ''*/ || this.Componentes_Producto.caracteristicas== '' ||
                    this.Componentes_Producto.marca == null){
                        this.showbutton_modificarproducto=false
                        return this.validacionmsg('No puede haber campos vacios','warning');
                    }else{
                        let formData = new FormData();
                        formData.append('id', this.Componentes_Producto.id);
                        formData.append('codigo', this.Componentes_Producto.codigo);
                        formData.append('nombre', this.Componentes_Producto.nombre);
                        //formData.append('descripcion', this.Componentes_Producto.descripcion);
                        formData.append('marca', this.Componentes_Producto.marca);
                        formData.append('fabricacion', this.Componentes_Producto.fabricacion);
                        formData.append('vencimiento', this.Componentes_Producto.vencimiento.split('-').reverse().join('-'));
                        //formData.append('duracion', this.Componentes_Producto.duracion);
                        formData.append('certificacion', this.Componentes_Producto.certificacion);
                        formData.append('presentacion', this.Componentes_Producto.presentacion);
                        //formData.append('envio', this.Componentes_Producto.envio);
                        formData.append('img', this.Componentes_Producto.imagen);
                        //formData.append('plazoentrega', this.Componentes_Producto.plazoentrega);
                        formData.append('caracteristicas', this.Componentes_Producto.caracteristicas);


                        axios.post('/api/modificar_productos',formData).then(res=>{
                            if(res.data.estado){
                                this.camposmsg(res.data.mensaje,res.data.icon);
                                this.Componentes_Producto=
                                    {codigo:null,
                                    nombre:null,
                                    //descripcion:null,
                                    marca:null,
                                    fabricacion:null,
                                    vencimiento:null,
                                    //duracion:null,
                                    certificacion:null,
                                    presentacion:null,
                                    //envio:null,
                                    //plazoentrega:null,
                                    caracteristicas:null,
                                    };
                            }
                        })
                    }
                }
            }
        },
        Volver(){
            this.Componentes_Producto=
                {codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                imagen:null,
                dir:null,
                };
                this.imagenminiatura=''
            this.$refs['Crear_Producto'].hide()
        },
        Volvereditar(){
            this.Componentes_Producto=
                {codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                imagen:null,
                dir:null,
                };
                this.imagenminiatura=''
            this.$refs['Modificar_Producto'].hide()
        },
        Volvervista(){
            this.Componentes_Producto=
                {codigo:null,
                nombre:null,
                //descripcion:null,
                marca:null,
                fabricacion:null,
                vencimiento:null,
                //duracion:null,
                certificacion:null,
                presentacion:null,
                //envio:null,
                //plazoentrega:null,
                caracteristicas:null,
                };
            this.$refs['modal-ver'].hide()
        },
        Eliminar(data){
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Componentes_Producto={id:data.id,};
                    axios.post('/api/eliminar_producto',this.Componentes_Producto).then(res=>{
                        if(res.data.estado){
                            this.camposmsg(res.data.mensaje,res.data.icon);
                            this.Componentes_Producto=
                                {
                                codigo:null,
                                nombre:null,
                                //descripcion:null,
                                marca:null,
                                fabricacion:null,
                                vencimiento:null,
                                //duracion:null,
                                certificacion:null,
                                presentacion:null,
                                //envio:null,
                                //plazoentrega:null,
                                caracteristicas:null,
                                };
                        }
                    })
                }
            })   
        },
        Exportarficha(){
            this.$refs.html2Pdf.generatePdf();
        },
        obtenerImagen(e){
        let file = e.target.files[0];
        //console.log(file);
        this.Componentes_Producto.imagen = file;
        //console.log(this.data.imagen);
        this.cargarImagen(file);
        },
        cargarImagen(file){
        let reader = new FileReader(); 
        reader.onload = (e) =>{
            this.imagenminiatura = e.target.result;
        }
        reader.readAsDataURL(file);
        },
        exportDocx(data) {
            console.log(data);
            const doc = new Document({         
            });
            const mimeType =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            const fileName = data.nombre+".docx";
            Packer.toBlob(doc).then((blob) => {
                const docblob = blob.slice(0, blob.size, mimeType);
                FileSaver.saveAs(docblob, fileName);
            });
        },
    },    
    created(){
        axios.get('/api/get_productos').then(res=>{
            this.productos = res.data.productos
            this.productos_repetidos = res.data.productos_repetidos
            this.productosRepetidos=res.data.productosRepetidos
            console.log(this.productosRepetidos)
            /* this.productos = res.data.productos.sort((a, b) => {
                return parseInt(a.codigo) - parseInt(b.codigo);
            }); */
        });
        axios.get('/api/get_marcas').then(res=>{
            this.marcas = res.data.marcas;
        })
        axios.get('/api/get_paises').then(res=>{
            this.paises = res.data.paises;
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="row pr-1">
                            <div class="col-10">
                                <h4 class="card-title">Productos</h4>
                            </div>
                            <div class="col-2 ">
                                <b-button class="float-right mr-0" variant="info" @click="CrearProducto()">Agregar Producto</b-button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table class="table table-striped" hover fixed 
                                :items="productos"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(marca)="datamarca">
                                    <p v-if="datamarca.item.marca=='null'"><i class="bx bx-error"></i> No registra marca</p>
                                    <p v-if="datamarca.item.marca!='null'">{{datamarca.item.marca}}</p>
                                </template>
                                <template v-slot:cell(fabricacion)="datafabricacion">
                                    <p v-if="datafabricacion.item.fabricacion=='null'"><i class="bx bx-error"></i> No registra fabricante</p>
                                    <p v-if="datafabricacion.item.fabricacion!='null'">{{datafabricacion.item.fabricacion}}</p>
                                </template>
                                <template v-slot:cell(vencimiento)="datavencimiento">
                                    <p v-if="datavencimiento.item.vencimiento=='null'"><i class="bx bx-error"></i> No registra vencimiento</p>
                                    <p v-if="datavencimiento.item.vencimiento!='null'">{{datavencimiento.item.vencimiento}}</p>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" variant="info" @click="showModalver(data.item)">Ver</b-button>
                                    <b-button size="sm" variant="info" @click="showModaleditar(data.item)" >Editar</b-button>
                                    <b-button size="sm" variant="info" @click="Eliminar(data.item)">Eliminar</b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                <!-- produstcvo repetidos solo admin -->
                        <div class="table-responsive mb-0" v-if="user.id<=2 && productosRepetidos.length>0">
                            <div class="col-10">
                                <h4 class="card-title">Productos repetidos ({{productosRepetidos.length}})</h4>
                            </div>
                            <br>
                            <b-table class="table table-striped" hover fixed 
                                :items="productos_repetidos"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(marca)="datamarca">
                                    <p v-if="datamarca.item.marca=='null'"><i class="bx bx-error"></i> No registra marca</p>
                                    <p v-if="datamarca.item.marca!='null'">{{datamarca.item.marca}}</p>
                                </template>
                                <template v-slot:cell(fabricacion)="datafabricacion">
                                    <p v-if="datafabricacion.item.fabricacion=='null'"><i class="bx bx-error"></i> No registra fabricante</p>
                                    <p v-if="datafabricacion.item.fabricacion!='null'">{{datafabricacion.item.fabricacion}}</p>
                                </template>
                                <template v-slot:cell(vencimiento)="datavencimiento">
                                    <p v-if="datavencimiento.item.vencimiento=='null'"><i class="bx bx-error"></i> No registra vencimiento</p>
                                    <p v-if="datavencimiento.item.vencimiento!='null'">{{datavencimiento.item.vencimiento}}</p>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" variant="info" @click="showModalver(data.item)">Ver</b-button>
                                    <b-button size="sm" variant="info" @click="showModaleditar(data.item)" >Editar</b-button>
                                    <b-button size="sm" variant="info" @click="Eliminar(data.item)">Eliminar</b-button>
                                </template>
                            </b-table>
                        </div>

                <!-- produstcvo repetidos solo admin -->
                        <b-modal ref="modal-ver" title="Ficha Técnica" hide-footer size="xl" @hidden="Volvervista()">
                            <div class="row justify-content-center">
                                <vue-html2pdf
                                    :show-layout="true"
                                    :float-layout="false"
                                    :enable-download="true"
                                    :preview-modal="false"
                                    :paginate-elements-by-height="1400"
                                    :filename="'Producto_'+ Componentes_Producto.nombre+'_'+ Componentes_Producto.codigo"
                                    :pdf-quality="2"
                                    :manual-pagination="false"
                                    pdf-format="legal"
                                    pdf-orientation="portrait"
                                    pdf-content-width="800px"
                                    @hasStartedGeneration="hasStartedGeneration()"
                                    @hasGenerated="hasGenerated($event)"
                                    ref="html2Pdf"
                                >
                                    <section slot="pdf-content">
                                        <!-- PDF Content Here -->
                                        <section class="pdf-item pt-3 pl-5 pr-5">
                                            <div>
                                                <img src="/archivos_base/logo.png" height="50" alt="logo"/> 
                                                <div class="row pb-3 ">
                                                    <div class="col-md-12 text-left" style="color:#000000">
                                                        <p class="p-0 m-0">Rut: 76.962.948-3</p>
                                                        <p class="p-0 m-0">Compra venta equipos médicos odontológicos</p>
                                                        <p class="p-0 m-0">venta de articulos médicos</p>
                                                        <p class="p-0 m-0">Errazuriz 2066 - VALDIVIA</p>
                                                        <p class="p-0 m-0">FONO (63) 2219881 /(63) 2204385</p>
                                                        <a href="">contacto@vamproden.cl - www.vamproden.cl</a>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 text-center" style="color:#000000">
                                                    <h3 class="p-0 m-0 mb-1">Ficha técnica</h3> 
                                                </div>
                                                
                                                <p> </p>
                                                <table class="table table-striped table-bordered" >
                                                    <thead class="thead-dark">
                                                        <tr>
                                                        <th class="col-2 py-1" scope="col">Caracteristicas</th>
                                                        <th class="col-8 py-1" scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr id="vista">
                                                            <td class="py-1" >Nombre: </td>
                                                            <td class="py-1"  >{{Componentes_Producto.nombre}}</td>
                                                        </tr>
                                                        <tr id="vista">
                                                            <td class="py-1" >Código: </td>
                                                            <td class="py-1" >{{Componentes_Producto.codigo}}</td>
                                                        </tr>
                                                        <!--
                                                        <tr v-if="Componentes_Producto.descripcion!='null'">
                                                            <td>Descripción </td>
                                                            <td>{{Componentes_Producto.descripcion}}</td>
                                                        </tr>
                                                        -->
                                                        <tr id="vista" v-if="Componentes_Producto.marca!='null' && Componentes_Producto.marca!==null">
                                                            <td class="py-1" >Marca:</td>
                                                            <td class="py-1" >{{Componentes_Producto.marca}}</td>
                                                        </tr>
                                                        <tr id="vista" v-if="Componentes_Producto.fabricacion!='null' && Componentes_Producto.fabricacion!==null">
                                                            <td class="py-1" >Fabricación:</td>
                                                            <td class="py-1" >{{Componentes_Producto.fabricacion}}</td>
                                                        </tr>
                                                        <tr id="vista" v-if="Componentes_Producto.vencimiento!='null' && Componentes_Producto.vencimiento!==null">
                                                            <td class="py-1" >Vencimiento:</td>
                                                            <td class="py-1" >{{Componentes_Producto.vencimiento}}</td>
                                                        </tr>
                                                        <!--
                                                        <tr v-if="Componentes_Producto.duracion!='null'">
                                                            <td>Duración </td>
                                                            <td>{{Componentes_Producto.duracion}}</td>
                                                        </tr>
                                                        -->
                                                        <tr  id="vista" v-if="Componentes_Producto.certificacion!='null' && Componentes_Producto.certificacion!==null">
                                                            <td class="py-1" >Certificacion:</td>
                                                            <td class="py-1" >{{Componentes_Producto.certificacion}}</td>
                                                        </tr>
                                                        <tr id="vista" v-if="Componentes_Producto.presentacion!='null' && Componentes_Producto.presentacion!==null">
                                                            <td class="py-1" >Presentación:</td>
                                                            <td class="py-1" >{{Componentes_Producto.presentacion}}</td>
                                                        </tr>
                                                        <!--
                                                        <tr v-if="Componentes_Producto.envio!='null'">
                                                            <td>Envío </td>
                                                            <td>{{Componentes_Producto.envio}}</td>
                                                        </tr>
                                                        <tr v-if="Componentes_Producto.plazoentrega!='null'">
                                                            <td>Plazo entrega </td>
                                                            <td>{{Componentes_Producto.plazoentrega}}</td>
                                                        </tr>
                                                        -->
                                                        
                                                        
                                                        <tr id="vista" v-if="Componentes_Producto.caracteristicas!='null' && Componentes_Producto.caracteristicas!==null && Componentes_Producto.caracteristicas!=''" class="my-0">
                                                            <td class="py-1">Caracteristicas:</td>
                                                            <td id="caracte_01" class="py-1"><p>{{Componentes_Producto.caracteristicas}}</p></td>
                                                        </tr>
                                                    </tbody>
                                                    </table>

                                            <div class="text-center">
                                                <img :src="Componentes_Producto.foto_dir_resize" height="195" alt="Imagen del producto" />
                                            </div>
                                                 <div class="html2pdf__page-break"/>
                                            </div>
                                        </section> 
                                    </section>
                                </vue-html2pdf>  
                            </div>                    
                            <div class="text-right">
                                <b-button variant="info" @click="Volvervista()">Volver</b-button>
                                <b-button variant="info" @click="Exportarficha()">Exportar ficha</b-button>  
                            </div>
                        </b-modal>
                        <b-modal ref="Modificar_Producto" title="Modificar producto" hide-footer size="xl" @hidden="Volvereditar()">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group id="Codigo_id" label-cols-sm="2" label-cols-lg="2" label="Código" label-for="text">
                                            <b-form-input id="Codigo_imput_id" for="text" v-model="Componentes_Producto.codigo" maxlength="60" ></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.codigo" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.codigo.length !== 60,
                                                'badge-danger': Componentes_Producto.codigo.length ===60 }">
                                                    {{ Componentes_Producto.codigo.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.nombre" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.nombre" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.nombre.length !== 60,
                                                'badge-danger': Componentes_Producto.nombre.length ===60 }">
                                                    {{ Componentes_Producto.nombre.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <!--
                                        <b-form-group id="Descripcion_id" label-cols-sm="2" label-cols-lg="2" label="Descripción" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.descripcion"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Marca</label>
                                            <div class="col-md-10">
                                                <select class="form-control" v-model="Componentes_Producto.marca">
                                                    <option :value="null">Seleccion marca</option>
                                                    <option value="sin marca">Sin marca</option>
                                                    <option v-for="item in marcas" :key="item.id" >{{item.nombre}} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Fabricación</label>
                                            <div class="col-md-10">
                                                <select class="form-control" v-model="Componentes_Producto.fabricacion">
                                                    <option :value="null">Seleccion pais</option>
                                                    <option value="sin marca">Sin Pais</option>
                                                    <option v-for="item in paises" :key="item.id" >{{item.nombre}} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Vencimiento</label>
                                            <div class="col-md-4">
                                                <b-form-input id="Vencimiento_imput_id" v-model="Componentes_Producto.vencimiento" type="date" :disabled="checked"></b-form-input>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-checkbox v-model="checked" switch class="mb-1 mt-2">
                                                    <label>Sin vencimiento</label>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <!--
                                        <b-form-group id="Duracion_id" label-cols-sm="2" label-cols-lg="2" label="Duración" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.duracion"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <b-form-group id="Certificación_id" label-cols-sm="2" label-cols-lg="2" label="Certificación" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.certificacion" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.certificacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.certificacion.length !== 60,
                                                'badge-danger': Componentes_Producto.certificacion.length ===60 }">
                                                    {{ Componentes_Producto.certificacion.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group id="Presentación_id" label-cols-sm="2" label-cols-lg="2" label="Presentación" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.presentacion" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.presentacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.presentacion.length !== 60,
                                                'badge-danger': Componentes_Producto.presentacion.length ===60 }">
                                                    {{ Componentes_Producto.presentacion.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <!--
                                        <b-form-group id="Envio_id" label-cols-sm="2" label-cols-lg="2" label="Envío" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.envio"></b-form-input>
                                        </b-form-group>
                                        <b-form-group id="plazoentrega_id" label-cols-sm="2" label-cols-lg="2" label="Plazo entrega" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.plazoentrega"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <b-form-group id="Caracteristicas_id" label-cols-sm="2" label-cols-lg="2" label="Caracteristicas" label-for="text">
                                            <!-- <b-form-input for="text" v-model="Componentes_Producto.caracteristicas" maxlength="2000"></b-form-input> -->
                                            <!-- <textarea class="form-control" for="text" v-model="Componentes_Producto.caracteristicas" maxlength="2000" rows="3"></textarea> -->
                                            <textarea class="form-control" for="text" v-model="Componentes_Producto.caracteristicas" maxlength="1500" rows="15"></textarea>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.caracteristicas" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.caracteristicas.length !== 1500,
                                                'badge-danger': Componentes_Producto.caracteristicas.length ===1500 }">
                                                    {{ Componentes_Producto.caracteristicas.length }} /
                                                    1500
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group id="example" label-cols-sm="2" label-cols-lg="2" label-for="text" label="Imagen :">
                                            <b-form-file
                                            v-model="Componentes_Producto.imagen"
                                            accept="image/png,image/jpeg,image/jpg"
                                            placeholder="Elegir Archivo"
                                            drop-placeholder="Drop file here..."
                                            @change="obtenerImagen"
                                            browse-text="Examinar"                             
                                            ></b-form-file>
                                        </b-form-group>
                                        <div class="text-center">
                                            <figure v-if="imagen==''">
                                                <p>*Imagen Actual - Para modificar, seleccione un nuevo archivo de imagen</p>
                                                <img :src="Componentes_Producto.foto_dir_resize" height="200" alt="Imagen del producto">
                                            </figure>
                                            <figure v-if="imagen!=''">
                                                <img :src="imagen" height="200" alt="Imagen del producto">
                                            </figure>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volvereditar()">Volver</b-button>
                                <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarproducto">Modificar</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="Crear_Producto" title="Crear producto" hide-footer size="xl" @hidden="Volver()">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group id="Codigo_id" label-cols-sm="2" label-cols-lg="2" label="Código :" label-for="text" >
                                            <b-form-input id="Codigo_imput_id" for="text" v-model="Componentes_Producto.codigo"   maxlength="60"  ></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.codigo" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.codigo.length !== 60,
                                                'badge-danger': Componentes_Producto.codigo.length ===60 }">
                                                    {{ Componentes_Producto.codigo.length }} /
                                                    60
                                                </p>
                                            </div>
                                            
                                        </b-form-group>
                                        
                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre :" label-for="text" >
                                            <b-form-input for="text" v-model="Componentes_Producto.nombre" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.nombre" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.nombre.length !== 60,
                                                'badge-danger': Componentes_Producto.nombre.length ===60 }">
                                                    {{ Componentes_Producto.nombre.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <!--
                                        <b-form-group id="Descripcion_id" label-cols-sm="2" label-cols-lg="2" label="Descripción :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.descripcion"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Marca :</label>
                                            <div class="col-md-10">
                                                <select class="form-control" v-model="Componentes_Producto.marca">
                                                    <option :value="null">Seleccion marca</option>
                                                    <option value="sin marca">Sin marca</option>
                                                    <option v-for="item in marcas" :key="item.id">{{item.nombre}} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Fabricación</label>
                                            <div class="col-md-10">
                                                <select class="form-control" v-model="Componentes_Producto.fabricacion">
                                                    <option :value="null">Seleccion pais</option>
                                                    <option value="sin marca">Sin Pais</option>
                                                    <option v-for="item in paises" :key="item.id" >{{item.nombre}} </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-md-2 col-form-label">Vencimiento</label>
                                            <div class="col-md-4">
                                                <b-form-input id="Vencimiento_imput_id" v-model="Componentes_Producto.vencimiento" type="date" :disabled="checked"></b-form-input>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-checkbox v-model="checked" switch class="mb-1 mt-2">
                                                    <label>Sin vencimiento</label>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <!--
                                        <b-form-group id="Duracion_id" label-cols-sm="2" label-cols-lg="2" label="Duración :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.duracion"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <b-form-group id="Certificación_id" label-cols-sm="2" label-cols-lg="2" label="Certificación :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.certificacion" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.certificacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.certificacion.length !== 60,
                                                'badge-danger': Componentes_Producto.certificacion.length ===60 }">
                                                    {{ Componentes_Producto.certificacion.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group id="Presentación_id" label-cols-sm="2" label-cols-lg="2" label="Presentación :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.presentacion" maxlength="60"></b-form-input>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.presentacion" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.presentacion.length !== 60,
                                                'badge-danger': Componentes_Producto.presentacion.length ===60 }">
                                                    {{ Componentes_Producto.presentacion.length }} /
                                                    60
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <!--
                                        <b-form-group id="Envio_id" label-cols-sm="2" label-cols-lg="2" label="Envío :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.envio"></b-form-input>
                                        </b-form-group>
                                        <b-form-group id="plazoentrega_id" label-cols-sm="2" label-cols-lg="2" label="Plazo entrega :" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Producto.plazoentrega"></b-form-input>
                                        </b-form-group>
                                        -->
                                        <b-form-group id="Caracteristicas_id" label-cols-sm="2" label-cols-lg="2" label="Caracteristicas :" label-for="text">
                                            <textarea class="form-control" for="text" v-model="Componentes_Producto.caracteristicas" maxlength="1500" rows="15"></textarea>
                                            <div class="text-left">
                                                <p v-if="Componentes_Producto.caracteristicas" class="badge position-absolute" :class="{ 'badge-primary': Componentes_Producto.caracteristicas.length !== 1500,
                                                'badge-danger': Componentes_Producto.caracteristicas.length ===1500 }">
                                                    {{ Componentes_Producto.caracteristicas.length }} /
                                                    1500
                                                </p>
                                            </div>
                                        </b-form-group>
                                        <b-form-group id="example" label-cols-sm="2" label-cols-lg="2" label-for="text" label="Imagen :">
                                            <b-form-file
                                            v-model="Componentes_Producto.imagen"
                                            accept="image/png,image/jpeg,image/jpg"
                                            placeholder="Elegir Archivo"
                                            drop-placeholder="Drop file here..."
                                            @change="obtenerImagen"
                                            browse-text="Examinar"                             
                                            ></b-form-file>
                                        </b-form-group>
                                        <div class="text-center">
                                            <figure v-if="imagen==''">
                                                <p>Para visualizar, seleccione archivo de imagen</p>
                                            </figure>
                                            <figure v-if="imagen!=''">
                                                <img :src="imagen" height="200" alt="Imagen del producto">
                                            </figure>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volver()">Volver</b-button>
                                <b-button variant="info" @click="Crear()" :disabled="showbutton_crearproducto">Crear</b-button>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.modal-header .close {
  display:none;
}
table {
    table-layout: fixed;
    word-wrap: break-word;
}


#vista{
   height: 10px;
}
textarea { resize: none; }

#caracte_01 { 
    white-space:pre-wrap;
    overflow: hidden 
}

</style>


