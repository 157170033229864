<!-- LoadingMessage.vue -->
<template>
    <div class="loading-message">
      <p>Cargando...</p>
      <p>espere unos segundos.</p>
      <i class="fas fa-spinner fa-spin"></i>
      <!-- Puedes agregar un spinner o algún otro indicador visual aquí -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingMessage',
  };
  </script>
  
  <style scoped>
  /* Estilos para el mensaje de carga, puedes personalizar según tus necesidades */
  .loading-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  /* Estilos para el spinner de Font Awesome */
  .fa-spinner {
    margin-top: 10px;
    font-size: 24px;
    color: #3498db; /* Puedes cambiar el color según tu diseño */
  }
  </style>