<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Tabla from "./components/table.vue"

export default {
  
    components: { Layout, PageHeader,Tabla },
    data() {
        return {
            title: "Despacho",
            licitaciones_filtradas:[],
            licitaciones_despachadas:[],
            Campos_No_despachados: [
                { key: "num", label:"ID",sortable: true },
                { key: "nombre", label:"Nombre",sortable: true },
                { key: "cliente", label:"Cliente",sortable: true },
                { key: "productos", label:"Productos pendientes",sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            Campos_despachados: [
                { key: "num", label:"ID",sortable: true },
                { key: "nombre", label:"Nombre",sortable: true },
                { key: "cliente", label:"Cliente",sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            mostrar:true,
            nomostrar:false
        };
    },
    methods: {

    },
    created () {
        axios.get('/api/traer_licitaciones_filtradas').then(res=>{
            this.licitaciones_filtradas=res.data.licitaciones
            //console.log(this.licitaciones_filtradas[0]);
            this.licitaciones_despachadas=res.data.licitaciones_despachadas
            //console.log(this.licitaciones_filtradas);
        })
    },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" />
    <!--Tabla de licitaciones no despachadas -->
    <Tabla 
    :datos="licitaciones_filtradas" 
    :campos="Campos_No_despachados" 
    :mostrarboton_ver="nomostrar" 
    :mostrarboton_despacho="mostrar" 
    nombre_tabla="No Despachados"></Tabla>

    <!--Tabla de licitaciones despachadas -->
    <Tabla class="mt-2"
    :datos="licitaciones_despachadas" 
    :campos="Campos_despachados" 
    :mostrarboton_ver="mostrar" 
    :mostrarboton_despacho="nomostrar" 
    nombre_tabla="Despachados"></Tabla>
  </Layout>
</template>