<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";

/**
 * Starter component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Vista Marcas",
            estudiante:'',
            items: [
                {
                    text: "Utility",
                    href: "/"
                },
                {
                    text: "Starter Page",
                    active: true
                }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "nombre",label:'Nombre', sortable: true },
                { key: "accion", label:"Acciones"},
            ],
            marcas:[],
            Componentes_Marca:{
                id:'',
                nombre:null,
            },
            showbutton_crearmarca:false,
            showbutton_modificarmarca:false,
        }
    },
    computed: {
        rows() {
            return this.marcas.length;
        }
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods:{
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        camposmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
                timer: 2500,
            }).then(()=>{
                window.location.href = `/marcas`;
            });
        },
        validacionmsg(text,icon) {
            Swal.fire({
                icon: icon,
                title: text,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Volver",
                iconColor:"#34c38f",
            })
        },
        CrearMarca(){
            this.$refs['Crear_Marca'].show();
        },
        Crear(){
            //console.log(this.Componentes_Marca);
            this.showbutton_crearmarca=true
            if(this.Componentes_Marca.nombre ==null || this.Componentes_Marca.nombre == '' || this.Componentes_Marca.nombre =='null'){
                this.showbutton_crearmarca=false
                return this.validacionmsg('No puede haber campos vacios','warning')
            }else(  
                axios.post('/api/crear_marca',this.Componentes_Marca).then(res=>{
                    if(res.data.estado){
                        this.camposmsg(res.data.mensaje,res.data.icon,);
                        this.Componentes_Marca=
                            {
                            nombre:null,
                            };
                    }
                })
            )      
        },
        showModaleditar(data){
            console.log(data);
            this.Componentes_Marca=data
            this.$refs['Modificar_Marca'].show();
        },
        Modificar(){
            //console.log(this.Componentes_Marca);
            this.showbutton_modificarmarca=true
            if(this.Componentes_Marca.nombre == ''){
                this.showbutton_modificarmarca=false
                return this.validacionmsg('No puede haber campos vacios','warning')
            }else(  
                axios.post('/api/modificar_marca',this.Componentes_Marca).then(res=>{
                    if(res.data.estado){
                        this.camposmsg(res.data.mensaje,res.data.icon,);
                        this.Componentes_Marca=
                            {
                            nombre:null,
                            };
                    }
                })
            )    
        },
        Eliminar(data){
            Swal.fire({
                title: '¿Estás seguro/a?',
                text: "Este cambio no puede ser revertido",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.Componentes_Marca={id:data.id,};
                    axios.post('/api/eliminar_marca',this.Componentes_Marca).then(res=>{
                        if(res.data.estado){
                            this.camposmsg(res.data.mensaje,res.data.icon);
                            this.Componentes_Producto=
                                {
                                nombre:null
                                };
                        }
                    })
                }
            })   
        },
        Volver(boton_modal){
            if(boton_modal==1){
                this.$refs['Crear_Marca'].hide();
            }else{
                this.Componentes_Marca=[]
                this.$refs['Modificar_Marca'].hide(); 
            }        
        },
    },
    created(){
        axios.get('/api/get_marcas').then(res=>{
            this.marcas = res.data.marcas;
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-body">
                        <div class="row pr-1">
                            <div class="col-10">
                                <h4 class="card-title">Marcas</h4>
                            </div>
                            <div class="col-2 ">
                                <b-button class="float-right mr-0" variant="info" @click="CrearMarca()">Agregar Marca</b-button>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table class="table table-striped" hover
                                :items="marcas"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(accion)="data">
                                    <b-button size="sm" variant="info" @click="showModaleditar(data.item)" >Editar</b-button>
                                    <b-button size="sm" variant="info" @click="Eliminar(data.item)">Eliminar</b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" pagination-active-color="white"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                        <b-modal ref="Crear_Marca" title="Crear Marca" hide-footer size="l" :no-close-on-backdrop="true" :close-on-esc="false">
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text">
                                            <b-form-input for="text" v-model="Componentes_Marca.nombre" placeholder="Ingrese nombre de la marca"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volver('1')">Volver</b-button>
                                <b-button variant="info" @click="Crear()" :disabled="showbutton_crearmarca">Crear</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="Modificar_Marca" title="Editar Marca" hide-footer size="l" :no-close-on-backdrop="true" :close-on-esc="false" >
                            <div class="row">
                                <div class="col-12">
                                    <form class="form-horizontal" role="form">
                                        <b-form-group id="Nombre_id" label-cols-sm="2" label-cols-lg="2" label="Nombre" label-for="text" >
                                            <b-form-input for="text" v-model="Componentes_Marca.nombre" placeholder="Ingrese nombre de la marca"></b-form-input>
                                        </b-form-group>
                                    </form>
                                </div>
                            </div>
                            <div class="text-right">
                                <b-button variant="info" @click="Volver('2')">Volver</b-button>
                                <b-button variant="info" @click="Modificar()" :disabled="showbutton_modificarmarca">Modificar</b-button>
                            </div>
                        </b-modal>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.modal-header .close {
  display:none;
}
</style>
