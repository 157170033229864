import { render, staticRenderFns } from "./footer2.vue?vue&type=template&id=0ee4f43a&scoped=true"
var script = {}
import style0 from "./footer2.vue?vue&type=style&index=0&id=0ee4f43a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ee4f43a",
  null
  
)

export default component.exports