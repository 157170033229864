<script>
import Axios from 'axios';
import Swal from "sweetalert2";


export default {
props: {
    datos:[],
    campos:[],
    mostrarboton_ver:'',
    mostrarboton_despacho:'',
    nombre_tabla:'',

},
components:{
    Swal,
},
data() {
    return {
        boton_ver:this.mostrarboton_ver,
        boton_despacho:this.mostrarboton_despacho,
        title: "Advanced Table",
        items: [
            {
            text: "Tables",
            href: "/"
            },
            {
            text: "Advanced",
            active: true
            }
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "age",
        sortDesc: false,
        fields: this.campos,
        Licitacion:[],
        Productos_Seleccionados:[],
        Select_All:false,
        Boton_despachar:true,
        Componentes_Despacho:{
            courier:null,
            direccion:null,
            fecha:null,
            seguimiento:null,
            correo:null,
            comentario:null,
            asunto:null,
            bulto:null,
            comuna:null,
        },
        courier:null,
        lectura:true,
        showbutton_guardardespacho:false,
        Datos_Reenvio_correo:{
            courier:null,
            direccion:null,
            fecha:null,
            seguimiento:null,
            correo:null,
            comentario:null,
            asunto:null,
            bulto:null,
            comuna:null,
        },
    };
},
watch: {
        Select_All: function(value,Oldvalue){
            if(value==true){
                //console.log(this.Licitacion.productos);  
                this.Licitacion.productos_no_despachados.forEach(element => {
                    if(!this.Productos_Seleccionados.includes(element)){
                        //console.log('element id: '+element.id);
                        this.Productos_Seleccionados.push(element)
                    }
                });
            }else{
                this.Productos_Seleccionados=[]
            }
        },
        Productos_Seleccionados: function (value,Oldvalue){
            //console.log('nuevo valor'+value);
            //console.log('viuejo valor'+Oldvalue);
            if(value==''){
                //console.log('entramos'+value);
                this.lectura=true
                this.Boton_despachar=true
            }else{
                //console.log('entramos al else'+value)
                this.lectura=false
                this.Boton_despachar=false
            }
        },
        
    },
computed: {
    rows() {
    return this.datos.length;
    },

},
mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
},
methods: {

    showModalver(data,vista){
        if(vista==1){
            this.Licitacion=data;
            this.$refs['Detalle_Despachos'].show();
        }else if(vista==2){
            //console.log('boton despacho');

            this.Licitacion=data;
            this.Licitacion.productos_no_despachados.forEach(element => {
                element.cantidad_total = element.cantidadadjudicacion
                element.cantidadadjudicacion = 0
            });
            console.log(this.Licitacion);
            this.$refs['Despachos'].show();
        }else if(vista==3){

            console.log(this.Productos_Seleccionados);
            let validador = 0
            this.Productos_Seleccionados.forEach(element => {
                if(element.cantidadadjudicacion>(element.cantidad_total-element.cantidad_despachados)){   
                    validador = validador + 1 
                    //console.log('es mayor!'+validador);
                    this.validadormsg('Cantidad mayor a la permitida','warning');
                }else if(element.cantidadadjudicacion<0){  
                    validador = validador + 1 
                    //console.log('es negativo!'+validador);
                    this.validadormsg('Cantidad negativa, no permitida','warning');   
                }else if (element.cantidadadjudicacion==0){       
                    validador = validador + 1 
                    //console.log('es cero!'+validador);
                    this.validadormsg('Cantidad igual a cero, no permitida','warning');
                }else if (!(element.cantidadadjudicacion % 1 == 0)){             
                    validador = validador + 1 
                    //console.log('es decimal!'+validador);
                    this.validadormsg('Cantidad decimal, no permitida','warning');
                } 
            });
            if (validador == 0 ){
                this.$refs['Formulario_Despacho'].show();
            }
            
            
        }
    },
    Volver(data){
        if(data==1){
            //console.log('boton ver');
            this.$refs['Detalle_Despachos'].hide();
        }else if(data==2){
            //console.log('boton despacho');
            this.Licitacion.productos_no_despachados.forEach(element => {
                element.cantidadadjudicacion = element.cantidad_total
            });
            this.Productos_Seleccionados=[]
            this.Select_All=false
            this.$refs['Despachos'].hide();
        }else if(data==3){
            this.$refs['Formulario_Despacho'].hide();
        }
    },
    Despachar(data){
        /* if(data == 1){
            this.Componentes_Despacho = this.Datos_Reenvio_correo 
        } */
        if(this.Componentes_Despacho.courier=='Despacho Vamproden'){
            this.Componentes_Despacho.seguimiento=''
        }
        let contador_validador=0;
        this.showbutton_guardardespacho=true
        for(const property in this.Componentes_Despacho){
            if(this.Componentes_Despacho[property]==null){
                //console.log(this.Componentes_Despacho[property]);
                contador_validador=contador_validador+1
            }
        }

        if(contador_validador==0){
            console.log(contador_validador);

            Swal.fire({
            title: '¿Estás seguro/a?',
            text: "Este cambio no puede ser revertido",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let data = {
                        datos_despacho: this.Componentes_Despacho,
                        productos:this.Productos_Seleccionados,
                        licitacion:this.Licitacion
                    }
                    Axios.post('/api/despachar',data).then(res=>{
                        if(res.data.estado){
                            this.camposmsg('Producto/s despachados correctamente','warning');
                        }
                    })
                }
            })
        } else {
            this.showbutton_guardardespacho=false
            return this.validadormsg('Campos vacíos')
        }  
    },

    camposmsg(text,icon) {
        Swal.fire({
            icon: icon,
            title: text,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "Volver",
            iconColor:"#34c38f",
            timer:3500,
            imageWidth: 1200,
            imageHeight: 1200,

        }).then(()=>{
            window.location.href = `/despacho`;
        });
    },

    validadormsg(text,icon) {
        Swal.fire({
            icon: icon,
            title: text,
            confirmButtonColor: "#34c38f",
            confirmButtonText: "Volver",
            iconColor:"#34c38f",
            //timer:3500,
            imageWidth: 1200,
            imageHeight: 1200,

        })
    },

    onFiltered(filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
    },
    Reenviar_correo(item){
        //console.log(item);
        this.Datos_Reenvio_correo=item
        this.$refs['Reenviar_Correo'].show();
    },
    Hidemodal(data){
        if(data == 1 ){

            this.$refs['Reenviar_Correo'].hide();
        }
    },
    Reenviar(){
        console.log(this.Datos_Reenvio_correo);
        Swal.fire({
            title: '¿Estás seguro/a?',
            text: "Este correo se enviará al destinatario/a seleccionado",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {  
                let data = {
                        datos_despacho: this.Datos_Reenvio_correo,
                    }       
                axios.post('/api/reenviar_correo',data)
                .then(res=> {
                if(res.data.estado){
                this.camposmsg('Despacho reenviado correctamente');
                }
                
                })
                .catch(function (error) {
                // manejar error
                if (error.response) {
                // La respuesta fue hecha y el servidor respondió con un código de estado
                // que esta fuera del rango de 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                } else if (error.request) {
                // La petición fue hecha pero no se recibió respuesta
                // `error.request` es una instancia de XMLHttpRequest en el navegador y una instancia de
                // http.ClientRequest en node.js
                console.log(error.request);
                } else {
                // Algo paso al preparar la petición que lanzo un Error
                console.log('Error', error.message);
                }
                console.log(error.config); 
                })
            }
        })

    }
},
created () {
   //console.log('datos: '+this.datos);
   Axios.get('/api/traer_courier').then(res=>{
    
    this.courier=res.data.courier;
   })
},
};
</script>

<template>

    <div class="row">
        <div class="col-12">
            <div class="card shadow-lg">
            <div class="card-body">
                <h4 class="card-title">{{nombre_tabla}}</h4>
                <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Mostrar&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;filas
                    </label>
                    </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                        Buscar:
                        <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Buscar..."
                        class="form-control form-control-sm ml-2"
                        ></b-form-input>
                    </label>
                    </div>
                </div>
                <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                <b-table class="table table-striped" hover
                    :items="datos"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                >
                    <template v-slot:cell(accion)="data">
                        <b-button size="sm" v-if="boton_ver" variant="info" @click="showModalver(data.item,1)">Ver</b-button>
                        <b-button size="sm" v-if="boton_despacho" variant="info" @click="showModalver(data.item,2)" >Despachar</b-button>
                    </template>
                    <template v-slot:cell(productos)="data2">
                        <p> {{data2.item.productos_no_despachados.length}} / {{data2.item.productos_no_despachados.length + data2.item.productos_despachados.length}}    </p>
                    </template>
                            
                </b-table>
                </div>
                <div class="row">
                <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <b-modal ref="Despachos" title="Productos no despachados" hide-footer size="xl" @hidden="Volver(2)">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                                            
                                    <b-form-checkbox   v-model="Select_All"> {{Select_All?'Deseleccionar':'Seleccionar todos'}}</b-form-checkbox>            
                                    <div class="table-responsive mt-2">
                                        <table class="table mb-0 table-striped table-hover"  >
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>Seleccionar</th>
                                                    <th>Unidades despachadas</th>
                                                    <th>Unidades a enviar</th>
                                                    <th>Total unidades</th>
                                                    <th>Nombre</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in Licitacion.productos_no_despachados" :key="item.id">
                                                    <th scope="row">
                                                        <b-form-checkbox  :value="item" v-model="Productos_Seleccionados"></b-form-checkbox>
                                                    </th>
                                                    <td class="text-center pt-3">   
                                                        {{item.cantidad_despachados}}  
                                                    </td>
                                                    <td>
                                                        <input  type="number" id="typeNumber"   class="form-control col-sm" min="0"  :max="item.cantidad_total-item.cantidad_despachados" v-model="item.cantidadadjudicacion" :readonly="lectura" />    
                                                    </td>
                                                    <td class="text-center pt-3">      
                                                        {{item.cantidad_total}}
                                                    </td>
                                                    <td>{{item.nombre}}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="row">
                                        <div v-for="item in Licitacion.despachos" :key="item.id" class="col-md-4 mt-3">
                                            <div >
                                                <b-card header-class="bg-transparent border-success" class="border border-success">
                                                    <template v-slot:header>
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <h5 class="my-0 text-success">
                                                                    <i class="mdi mdi-check-all mr-3"></i>Despacho
                                                                </h5>
                                                            </div>
                                                            <div class="col-6">
                                                                <b-button class="ml-5 mt-0" size='sm' variant='info' @click='Reenviar_correo(item)'>Reenviar</b-button>
                                                            </div>
                                                        </div>
                                                            <p class="card-text"><i class="bx bx-car mr-3 mt-2"></i>{{item.courier}}</p>
                                                            <p class="card-text"><i class="bx bx-map mr-3"></i>{{item.direccion}}</p>
                                                            <p class="card-text"><i class="bx bx-calendar mr-3"></i>{{item.fecha.split('-').reverse().join('-')}}</p>
                                                            <p class="card-text"><i class="bx bx-map-alt mr-3" v-if="item.courier!='Despacho Vamproden'"></i>{{item.seguimiento}}</p>
                                                            <p class="card-text"><i class="bx bx-mail-send mr-3"></i>{{item.correo}}</p>

                                                    </template>
                                                    <h5 class="card-title mt-0">Productos</h5>
                                                    <div v-for="item in item.productos" :key="item.id">             
                                                        <p class="card-text">-{{item.nombre}}</p>
                                                    </div>
                                                
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <b-button variant="info" @click="Volver(2)">Volver</b-button>
                <b-button  :disabled="Boton_despachar" variant="info" @click="showModalver(undefined,3)">Despachar</b-button>
            </div>
        </b-modal>
        <b-modal ref="Detalle_Despachos" title="Detalle despachos" hide-footer size="lg" @hidden="Volver(1)">
                <div class="row ml-2">
                    <div class="col-12">
                        <p><strong>ID:</strong> {{Licitacion.num}}</p>
                        <p><strong>Nombre:</strong> {{Licitacion.nombre}}</p>
                        <p><strong>Cliente:</strong> {{Licitacion.cliente}}</p>
                        <p><strong>Productos:</strong></p>
                        <div class="row" v-for="item in Licitacion.productos_despachados" :key="item.id">
                            <div class="col-9">
                                <strong>• Nombre:</strong> {{item.nombre}} 
                            </div>
                            <div class="col-3">
                                <strong>• Cantidad:</strong> {{item.cantidad_despachados}}
                            </div>
                        </div>
                    </div>

                </div>
            <div class="row">    
                <div v-for="item in Licitacion.despachos" :key="item.id" class="col-md-4 mt-3">
                
                    <div >
                        <b-card header-class="bg-transparent border-success" class="border border-success">
                            <template v-slot:header>
                                <div class="row">
                                    <div class="col-8" >
                                        <h5 class="my-0 text-success">
                                            <i class="mdi mdi-check-all mr-3"></i>Despacho
                                        </h5>
                                    </div>
                                    <div class="col-4">
                                        <b-button class="mr-5 mt-0" size='sm' variant='info' @click='Reenviar_correo(item)'>Reenviar</b-button>
                                    </div>
                                </div>
                                    <p class="card-text"><i class="bx bx-car mr-3 mt-2"></i>{{item.courier}}</p>
                                    <p class="card-text"><i class="bx bx-map mr-3"></i>{{item.direccion}}</p>
                                    <p class="card-text"><i class="bx bx-calendar mr-3"></i>{{item.fecha.split('-').reverse().join('-')}}</p>
                                    <p class="card-text"><i class="bx bx-map-alt mr-3" v-if="item.courier!='Despacho Vamproden'"></i>{{item.seguimiento}}</p>
                                    <p class="card-text"><i class="bx bx-mail-send mr-3"></i>{{item.correo}}</p>
                                <!---------- Botón ---------->
                            </template>
                            <h5 class="card-title mt-0">Productos</h5>
                            <div v-for="item in item.productos" :key="item.id">             
                                <p class="card-text">•{{item.nombre}}</p>
                            </div>
                        
                        </b-card>
                    </div>
                </div>
            </div>                             
            <div class="text-right mt-3">
                <b-button variant="info" @click="Volver(1)">Volver</b-button>

            </div>
        </b-modal>
        <b-modal ref="Formulario_Despacho" title="Formulario despacho" hide-footer size="xl" @hidden="Volver(3)">
            <div class="row">
                <div class="col-12">
                    
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                
                                <div class="form-group row">
                                    <label class="col-md-2 col-form-label">Courier</label>
                                    <div class="col-md-10">
                                        <select class="form-control" v-model="Componentes_Despacho.courier">
                                            <option value="null">Seleccionar</option>
                                            <option v-for="item in courier" :key="item.id">{{item.nombre}}</option>
                                        </select>
                                    </div>
                                </div>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Dirección" label-for="text">
                                    <b-form-input for="text" placeholder="Ingrese Dirección" v-model="Componentes_Despacho.direccion"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Comuna" label-for="text">
                                    <b-form-input for="text" placeholder="Ingrese Comuna" v-model="Componentes_Despacho.comuna"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example-date" label-cols-sm="2" label-cols-lg="2" label="Fecha" label-for="date">
                                    <b-form-input id="date" placeholder="Ingrese Fecha" type="date" v-model="Componentes_Despacho.fecha"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cantidad Bulto" label-for="text">
                                    <b-form-input for="text"  placeholder="Bulto..." v-model="Componentes_Despacho.bulto"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Seguimiento" v-if="Componentes_Despacho.courier!='Despacho Vamproden'" label-for="text">
                                    <b-form-input for="text" placeholder="n° seguimiento..." v-model="Componentes_Despacho.seguimiento"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Correo" label-for="text">
                                    <b-form-input for="text"  placeholder="Correo..." v-model="Componentes_Despacho.correo"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Asunto ID" label-for="text">
                                    <b-form-input for="text"  placeholder="Asunto ID..." v-model="Componentes_Despacho.asunto"></b-form-input>
                                </b-form-group>

                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Comentario" label-for="text">
                                    <b-form-input for="text"  placeholder="Comentario..." v-model="Componentes_Despacho.comentario"></b-form-input>
                                </b-form-group>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <b-button variant="info" @click="Volver(3)">Volver</b-button>
                <b-button variant="info" @click="Despachar()" :disabled="showbutton_guardardespacho">Guardar</b-button>
            </div>
        </b-modal>
        <!-- -------- Modal -------- -->
        <b-modal
        ref='Reenviar_Correo'
        title='Reenviar correo'
        hide-footer
        size='lg'
        @hidden='Hidemodal(1)'
        :no-close-on-backdrop='true'>
        <!---------- Contenido modal ---------->
        <div class='col-md-12'>
            <div class='row'>
                <div class="col-12">
                    <form class="form-horizontal" role="form">
                                    
                        <div class="form-group row">
                            <label class="col-md-2 col-form-label">Courier</label>
                            <div class="col-md-10">
                                <select class="form-control" v-model="Datos_Reenvio_correo.courier">
                                    <option value="null">Seleccionar</option>
                                    <option v-for="item in courier" :key="item.id">{{item.nombre}}</option>
                                </select>
                            </div>
                        </div>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Dirección" label-for="text">
                            <b-form-input for="text" placeholder="Ingrese Dirección" v-model="Datos_Reenvio_correo.direccion"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Comuna" label-for="text">
                            <b-form-input for="text" placeholder="Ingrese Comuna" v-model="Datos_Reenvio_correo.comuna"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example-date" label-cols-sm="2" label-cols-lg="2" label="Fecha" label-for="date">
                            <b-form-input id="date" placeholder="Ingrese Fecha" type="date" v-model="Datos_Reenvio_correo.fecha"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Cantidad Bulto" label-for="text">
                            <b-form-input for="text"  placeholder="Bulto..." v-model="Datos_Reenvio_correo.bulto"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Seguimiento" v-if="Datos_Reenvio_correo.courier!='Despacho Vamproden'" label-for="text">
                            <b-form-input for="text" placeholder="n° seguimiento..." v-model="Datos_Reenvio_correo.seguimiento"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Correo" label-for="text">
                            <b-form-input for="text"  placeholder="Correo..." v-model="Datos_Reenvio_correo.correo"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Asunto ID" label-for="text">
                            <b-form-input for="text"  placeholder="Asunto ID..." v-model="Datos_Reenvio_correo.asunto"></b-form-input>
                        </b-form-group>

                        <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Comentario" label-for="text">
                            <b-form-input for="text"  placeholder="Comentario..." v-model="Datos_Reenvio_correo.comentario"></b-form-input>
                        </b-form-group>

                    </form>
                </div>
            </div>
        </div>
        <div class="text-right">
            <b-button variant="info" @click="Hidemodal(1)">Volver</b-button>
            <b-button variant="info" @click="Reenviar()" :disabled="showbutton_guardardespacho">Guardar</b-button>
        </div>
        </b-modal>
    </div>

</template>
