import { render, staticRenderFns } from "./base.vue?vue&type=template&id=40bbc5e5&scoped=true"
import script from "./base.vue?vue&type=script&lang=js"
export * from "./base.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40bbc5e5",
  null
  
)

export default component.exports