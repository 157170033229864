<script>
    export default {
        props:{
            titulo:'',
            productos:[],
        },
    }
</script>

<template>
    <div>
        <h5 class="mb-3">{{titulo}}</h5>
        <div class="row">
            <div class="col-xl-4 col-sm-12 border m-2" v-for="prod in productos" :key="prod.id">
                <a :href="'/producto/'+prod.id">
                    <div class="card">
                        <div class="card-body">
                            <div class="row align-items-center">
                                <div class="col-xl-4 col-sm-12 col-xs-12">
                                    <img
                                        :src="prod.foto_dir==null? '/images/productos_web/producto-sin-imagen.png': prod.foto_dir"
                                        alt
                                        class="avatar-xl img-fluid d-block"
                                    />
                                </div>
                                <div class="col-md-8">
                                    <div class="text-center text-md-left">
                                        <h5 class="mb-3">
                                            <a :href="'/producto/'+prod.id" class="text-dark">{{ prod.nombre }}</a>
                                        </h5>
                                        <a class="text-primary" :href="'/filtrar/marca/'+prod.marca">{{ prod.nombre_marca }}</a>
                                        <h5 class="my-0" v-if="prod.precio_rebajado!=null && prod.tipo=='Simple'">
                                            <span class="text-muted mr-2">
                                                <del>${{ prod.precio_normal }}</del>
                                            </span>
                                            <b>${{ prod.precio_rebajado }}</b>
                                        </h5>
                                        <h5 class="my-0" v-else-if="prod.precio_rebajado==null && prod.tipo=='Simple'">
                                            <b>${{ prod.precio_normal }}</b>
                                        </h5>
                                        <h5 class="my-0" v-else-if="prod.precio_rebajado!=0 && prod.tipo=='Variable'">
                                            <span class="text-muted mr-2">
                                                <del>Desde ${{ prod.precio_normal }}</del>
                                            </span>
                                            <b>Desde ${{ prod.precio_rebajado }}</b>
                                        </h5>
                                        <h5 class="my-0" v-else-if="prod.precio_rebajado==0 && prod.tipo=='Variable'">
                                            <b>Desde ${{ prod.precio_normal }}</b>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>