<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";

/**
 * Advanced table component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Advanced Table",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            ordenes:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "created_at",
            sortDesc: false,
            fields: [
                { key: "uid",label:"Folio", sortable: true },
                { key: "status",label:"Estado", sortable: true },
                { key: "items", label:"Cantidad de Items", sortable: true },
                { key: "total",label:"Total", sortable: true },
                { key: "usuario", label:"Usuario", sortable: true },
                { key: "acciones", label:"Acciones", sortable: true }
            ]
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.ordenes.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    created(){
        axios.get('/api/get_ordenes').then(res=>{
            this.ordenes = res.data.ordenes;
            //console.table(this.productos);
        })
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Ordenes de Compra</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;ordenes
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="ordenes"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(items)="data">
                                    {{JSON.parse(data.item.cart_items).length}}
                                </template>
                                <template v-slot:cell(total)="data">
                                    {{ data.item.cart_total }}
                                </template>
                                <template v-slot:cell(usuario)="data">
                                </template>
                                <template v-slot:cell(acciones)="data">
                                    <!-- <b-button size="sm" variant="danger" @click="eliminar(data.item)"><i class="bx bx-trash"></i></b-button> -->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>