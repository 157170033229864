<script>
import Layout from "./base";
import ProductosDestacados from "../componentes/destacados";
import Swal from "sweetalert2";

/**
 * Starter component
 */
export default {
    components: { Layout, ProductosDestacados,Swal },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:'',
        producto:{}
    },
    data() {
        return {
            item:1,
            scroll:0,
            orden:[],
            espscroll:0,
            productos_dest:[],
            seleccion:{},
            precio_variable:false,
            screen:{}
        };
    },
    methods: {
        sumar(){
            if (this.item<this.producto.stock) {
                this.item++;
            }
        },
        restar(){
            if (this.item>0) {
                this.item--;
            }
        },
        variacion(){
            let atributos = this.producto.atributos;
            let titulo=this.orden.join("-");
            this.producto.variaciones.forEach(element => {
                if (element.titulo==titulo) {
                    this.seleccion.variacion_id=element.id;
                    this.seleccion.precio=element.precio_rebajado!=''?parseInt(element.precio_rebajado):parseInt(element.precio_normal);
                    this.seleccion.precio_rebajado=element.precio_rebajado;
                    this.seleccion.precio_normal=element.precio_normal;
                    this.producto.estado_inventario=element.estado_inventario;
                    this.producto.stock=element.stock;
                    this.seleccion.stock=element.stock;
                    this.seleccion.titulo=element.titulo;
                    this.seleccion.variacion=this.orden;
                    this.seleccion.porcentaje=element.porcentaje;
                    this.seleccion.ndescuento=element.ndescuento;
                    this.precio_variable=true;
                }
            });
        },
        agregar_carro(){
            this.seleccion.cantidad=this.item;
            console.log(this.seleccion);
            localStorage.setItem('orden',JSON.stringify(this.seleccion));
            this.$store.dispatch('addToCart', this.seleccion);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Se ha agregado un producto",
                showConfirmButton: false,
                timer: 1500
            });
            this.act=this.act++;
            //this.$carrito.push(this.seleccion);
        }
    },
    mounted(){
    },
    created () {
        axios.get('/api/get_productos_web_destacados').then(res=>{
            this.productos_dest = res.data.productos;
            //console.table(this.productos);
        })
        this.seleccion.producto_id=this.producto.id;
        this.seleccion.tipo=this.producto.tipo;
        this.seleccion.nombre=this.producto.nombre;
        this.seleccion.codigo=this.producto.codigo;
        this.seleccion.marca=this.producto.marca;
        this.seleccion.imagen=this.producto.foto_dir;
        this.seleccion.cantidad='';
        if (this.producto.tipo=='Simple') {
            this.seleccion.precio=this.producto.precio_rebajado!=null?this.producto.precio_rebajado:this.producto.precio_normal;
            this.seleccion.precio_rebajado=this.producto.precio_rebajado;
            this.seleccion.precio_normal=this.producto.precio_normal;
            this.seleccion.titulo=this.producto.nombre;
            this.seleccion.stock=this.producto.stock;
        }else if (this.producto.tipo=='Variable') {
            this.seleccion.precio='';
            this.seleccion.precio_rebajado='';
            this.seleccion.precio_normal='';
            this.seleccion.titulo='';
        }
        this.screen=window.screen
        
    },
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div class="mt-5">
            <div :id="screen.width<=576? '':'cover_container'" class="sticky_cover_container">
                <div :id="screen.width<=576? '':'cover_sticky'" class="two_column_cover_sticky">
                    <div>
                        <b-tabs pills vertical align="center">
                            <b-tab v-for="(imagen,index) in producto.imagenes" :key="index">
                                <template #title>
                                    <img
                                    :src="imagen"
                                    alt
                                    :class="screen.width<=768? 'avatar-sm':'avatar-lg'"
                                    />
                                </template>
                                <div class="cont">
                                    <img
                                        :src="imagen"
                                        alt
                                        class=" cont_img"
                                        />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div :id="screen.width<=576? '':'cover_text'" class="two_column_cover_text">
                    <div class="">
                        <a href="#" class="text-primary">{{producto.nombre_marca}}</a>
                        <div v-if="producto.cat.length>=1 && (producto.cat[0]!=null || producto.cat[0]!='')">
                            <a href="#" class="text-primary" v-for="(categoria,index) in producto.cat" :key="index">{{categoria.nombre}}</a> 
                        </div>
                        <h4 class="mt-1 mb-3">{{producto.nombre}}</h4>
                        <p>SKU: {{producto.codigo}}</p>
                        <div v-if="producto.tipo=='Simple'">
                            <h6 class="text-success text-uppercase" v-if="producto.precio_rebajado!=null">{{producto.porcentaje}} % descuento</h6>
                            <h5 class="mb-4" v-if="producto.precio_rebajado!=null">
                                Precio :
                                <span class="text-muted mr-2">
                                    <del>${{ producto.precio_normal }} </del>
                                </span>
                                <b>${{ producto.precio_rebajado }} </b>
                            </h5>
                            <h5 class="mb-4" v-else>
                                Precio :
                                <b>${{ producto.precio_normal }} </b>
                            </h5>
                        </div>
                        <div class="row" v-else-if="producto.tipo=='Variable'">
                            <div class="col-md-12" v-if="precio_variable">
                                <h6 class="text-success text-uppercase" v-if="seleccion.precio_rebajado!=''">{{seleccion.porcentaje}} % descuento</h6>
                                <h5 class="mb-4" v-if="seleccion.precio_rebajado!=''">
                                    Precio :
                                    <span class="text-muted mr-2">
                                        <del>${{ seleccion.precio_normal }} </del>
                                    </span>
                                    <b>${{ seleccion.precio_rebajado }} </b>
                                </h5>
                                <h5 class="mb-4" v-else>
                                    Precio :
                                    <b>${{ seleccion.precio_normal }}</b>
                                </h5>
                            </div>
                            <div class="col-md-10 mb-3" v-for="(atributo,index0) in producto.atributos" :key="index0">
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="5" :label="atributo.nombre+' :'" label-size="lg" label-for="text" >
                                    <select class="form-control" v-model="orden[index0]" @change="variacion">
                                        <option value="">Seleccionar</option>
                                        <option :value="valor" v-for="(valor,index) in JSON.parse(atributo.valores_array)" :key="'valor'+atributo.id+index">{{ valor}}</option>
                                    </select>
                                </b-form-group>                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4" v-if="producto.estado_inventario=='hay'">
                                <b-input-group>
                                    <b-input-group-prepend>
                                        <b-button variant="outline-primary" @click="restar()">-</b-button>
                                    </b-input-group-prepend>
                                    <b-form-input class="text-center" v-model="item"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-primary" @click="sumar()">+</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <p class="p-0 m-0 pt-3 pb-3">{{producto.stock}} disponibles</p>
                                <div class="">
                                    <button type="button" class="btn btn-primary mr-1" @click="agregar_carro()">
                                        <i class="bx bx-cart mr-2"></i> Agregar al carro
                                    </button>
                                </div>
                            </div>
                            <div class="col-md-4" v-else-if="producto.estado_inventario=='no hay'">
                                <p>Producto No disponible</p>
                            </div>
                            <div class="col-md-4" v-else-if="producto.estado_inventario=='reservar'">
                                <a class="mt-3" href="#"><i class="bx bx-envelope"></i>Solicita aquí si no encuentras el producto</a>
                            </div>
                        
                        </div>
                        <br>
                        <div class="texto-con-saltos">
                            {{ producto.desccorta }}
                        </div>
                        <div class="texto-con-saltos">
                            {{ producto.desclarga }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div id="especific_container">
                <h5 class="mb-3">Especificaciones :</h5>
                <div class="table-responsive">
                    <table class="table mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th scope="row" style="width: 400px;">Presentación</th>
                            <td>125mg</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->
            <div id="product_container" class="row mt-3 mb-5" v-if="productos_dest.length>0">
                <div class="col-lg-12">
                    <ProductosDestacados :productos="productos_dest" :titulo="'Productos destacados :'"/>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style scoped>
#cover_container {

position:relative;
margin: 3vw;
padding-top: 0;
margin-top: 0;

}
#cover_sticky{
position:sticky;
top:10vh;
width:50%;

}
#cover_text{
/*should be the same as the image height*/
    margin-top:-50vh;
    width:50%;
    heigth:fit-content;
    margin-left:50%;
}
#product_container {

margin: 3vw;

}
#especific_container {

margin: 3vw;

}
.cont{
    width: 550px;
    height: 550px;
}
.cont_img{
    object-fit: cover;
    width:100%;
    height:100%;
}
@media (max-width: 1520px) { 
    .cont{
        width: 400px;
        height:400px;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
}
@media (max-width: 768px) {
    .cont{
        width: 200px;
        height:200px;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
    #cover_text{
    /*should be the same as the image height*/
        margin-top:-20vh;
    }
}
@media (max-width: 576px){

}
.texto-con-saltos {
  white-space: pre-line;
}


</style>