<script>
import Swal from "sweetalert2";
/**
 * Right sidebar component
 */
export default {
    components: {Swal},
    props:{
    },
    data() {
        return {
            config: {
                handler: this.handleRightBarClick,
                middleware: this.middleware,
                events: ["click"],
            },
        };
    },
    computed:{
        cartItems(){
            return this.$store.getters.cartItems;
        },
        cartTotal() {
            return this.$store.getters.cartTotal;
        }
    },
    methods: {
        hide() {
            this.$parent.toggleRightSidebar();
        },
        // eslint-disable-next-line no-unused-vars
        handleRightBarClick(e, el) {
            this.$parent.hideRightSidebar();
        },
        // eslint-disable-next-line no-unused-vars
        middleware(event, el) {
            return !event.target.classList.contains("toggle-right");
        },
        removeFromCart(index) {
            this.$store.dispatch('removeFromCart', index);
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Se ha eliminado un producto",
                showConfirmButton: false,
                timer: 1500
            });
        }
    },
};
</script>

<template>
<div>
    <div v-click-outside="config" class="right-bar">
        <div v-if="cartItems.length>0">
            <div class="">
                <div class="rightbar-title px-3 py-4">
                    <a href="javascript:void(0);" class="right-bar-toggle float-right" @click="hide">
                        <i class="mdi mdi-close noti-icon"></i>
                    </a>
                    <h5 class="m-0">Resumen de compras</h5>
                </div>
                <div class="shopping_cart px-3 py-4">
                    <div class="card mini-stats-wid border" v-for="(prod,index) in cartItems" :key="'carrito'+index">
                        <div class="card-body">
                            <div class="media">
                                <div class="mini-stat-icon align-self-center">
                                    <!-- <span class="avatar-title">
                                        <i :class="`bx bx-trash font-size-24`"></i>
                                    </span> -->
                                    <img :src="prod.imagen" class="avatar-sm rounded-circle" alt="">
                                </div>
                                <div class="media-body ml-4">
                                    <p class="text-muted font-weight-small">{{prod.nombre}}</p>
                                    <p class="text-muted font-size-10 mb-0" v-if="prod.tipo=='Variable'">Variación: {{ prod.titulo }}</p>
                                    <p class="text-muted font-size-10">Cantidad: {{ prod.cantidad }}</p>
                                    <p class="text-primary mb-0">${{ prod.precio }}</p>
                                </div>
                                <div class="align-self-center">
                                    <b-button size="sm" variant="none" @click="removeFromCart(index)"><i class="bx bx-trash font-size-14"></i></b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bar-footer">
                <div class="row">
                    <div class="col-md-6">
                        <p class="ml-3 text-primary font-size-14"><b>Total: </b></p>
                    </div>
                    <div class="col-md-6 text-right">
                        <p class="ml-3 text-primary font-size-14"><b>$ {{ cartTotal }}</b></p>
                    </div>
                </div>
                
                <b-button class="ml-3 mr-3" variant="primary" block href="/resumen_compra">Finalizar compra</b-button>
            </div>
        </div>
        <div class="text-center mx-4" v-else style="margin-top: 15vw;">
            <h4>Aún no se han agregado productos al carrito.</h4>
            <a href="/filtrar">Ver todo</a>
        </div>
    </div>

    <!-- Right bar overlay-->
    <div class="rightbar-overlay">
        
    </div>
</div>
</template>

<style lang="scss">

</style>
